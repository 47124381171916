<template>
  <!-- Page title -->
  <div class="w-100">
    <div class="d-flex flex-column w-100">
      <div
        class="page-title d-flex align-items-center bg-image py-5"
        style="background-image: url('assets/images/contact-us-bg.jpg')"
      >
        <div class="container page-title-container">
          <div class="row">
            <div class="col text-center">
              <h1 class="display-3 font-weight-800 text-white mb-4 mb-md-0">
                Contact Us
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col">
          <div class="bg-danger py-4 mt-up50 rounded-xl shadow-lg">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb breadcrumb-light-2 px-md-4 mb-0">
                <li class="breadcrumb-item text-uppercase">
                  <router-link to="/">Home</router-link>
                </li>
                <li
                  class="breadcrumb-item text-uppercase active"
                  aria-current="page"
                >
                  Contact Us
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Content -->
  <div class="main-content pb-0">
    <div class="section">
      <div class="container">
        <!-- Contact form box -->
        <div
          class="
            row
            no-gutters
            position-relative
            overflow-hidden
            z-index-1
            shadow-lg
            rounded-xl
          "
        >
          <div class="col-lg-7 bg-white px-3 py-5 p-md-5">
            <!-- <div class="px-3 py-4">

              <h3 class="section-title-3 text-left font-weight-700 mb-4">Get in Touch</h3>

              <form class="pt-4">
                <div class="row">

                  <div class="form-group col-md-6 pb-3 mb-3">
                    <input type="text" class="form-control form-round form-control-lg" placeholder="Name *"
                      required>
                  </div>

                  <div class="form-group col-md-6 pb-3 mb-3">
                    <input type="email" class="form-control form-round form-control-lg" placeholder="Email *"
                      required>
                  </div>

                  <div class="form-group col-12 pb-3 mb-3">
                    <input type="text" class="form-control form-round form-control-lg" placeholder="Subject *"
                      required>
                  </div>

                  <div class="form-group col-12 mb-5">
                    <textarea class="form-control form-round form-control-lg py-3" rows="7"
                      placeholder="Your message *" required></textarea>
                  </div>

                  <div class="col-md-6 col-lg-12 col-xl-6">
                    <button type="submit" class="btn btn-block btn-round btn-lg btn-danger">Send your
                      message</button>
                  </div>

                </div>
              </form>

            </div> -->
          </div>

          <div class="col-lg-5">
            <div
              class="contact-box h-100 px-4 py-5 text-white-75 bg-image"
              style="
                background-image: url('assets/images/contact-box-bg-05.jpg');
              "
            >
              <div class="px-xl-3 py-4">
                <h3
                  class="
                    section-title-3
                    title-light-1
                    text-left
                    font-weight-700
                    mb-4
                  "
                >
                  Contact Info
                </h3>

                <div class="row contact-info-list pt-4 mb-5">
                  <div class="col-md-6 col-lg-12 contact-info-item">
                    <div class="icon-info-1">
                      <div class="icon-element text-white pt-1">
                        <i class="fas fa-map-marker-alt fa-2x"></i>
                      </div>
                      <div class="icon-info-text pl-2">
                        <h6 class="mb-1 font-weight-700 text-white">Address</h6>
                        <p class="mb-0">
                          30 West 4th Street<br />Cincinnati, OH 45202
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-lg-12 contact-info-item">
                    <div class="icon-info-1">
                      <div class="icon-element text-white pt-1">
                        <i class="fas fa-mobile-alt fa-2x"></i>
                      </div>
                      <div class="icon-info-text pl-2">
                        <h6 class="mb-1 font-weight-700 text-white">Phone</h6>
                        <a href="tel:+1 212 509 4000" class="text-white-75"
                          >+1 212 509 4000</a
                        ><br />
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-lg-12 contact-info-item">
                    <div class="icon-info-1">
                      <div class="icon-element text-white pt-1">
                        <i class="fas fa-paper-plane fa-2x"></i>
                      </div>
                      <div class="icon-info-text pl-2">
                        <h6 class="mb-1 font-weight-700 text-white">Email</h6>
                        <a href="mailto:BeepIR@Advisiry.com" class="text-white-75"
                          >BeepIR@Advisiry.com</a
                        ><br />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="pt-2 px-2">
                  <a
                    v-for="s in socials"
                    :key="s.linkType"
                    :href="s.link"
                    target="_blank"
                    class="btn btn-sm btn-round btn-indigo mr-1"
                  >
                    <i :class="getIcon(s.linkType)"></i>{{ s.linkType }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Google map section -->
    <div class="section">
      <div class="container-fluid px-0">
        <div class="row map-wrapper no-gutters mt-up50 mt-md-up75 mt-lg-up125">
          <div class="col-12 map-container" id="map"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["socials"]),
  },
  methods: {
    getIcon(type) {
      switch (type) {
        case "LinkedIn":
          return "fab fa-linkedin-in";
        case "Facebook":
          return "fab fa-facebook-square";
        case "Google":
          return "fab fa-google";
        case "Twitter":
          return "fab fa-twitter-square";

        default:
          break;
      }
    },
  },
  mounted() {
    mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_ACCESS_TOKEN;

    const map = new mapboxgl.Map({
      container: "map",
      style: process.env.VUE_APP_MAPBOX_STYLE,
      center: [-84.50849416297689, 39.102374473063655],
      zoom: 16,
    });
    map.scrollZoom.disable();
    new mapboxgl.Marker({ color: "#e53935" })
      .setLngLat([-84.50849416297689, 39.102374473063655])
      .addTo(map);
  },
};
</script>

<style lang="scss" scoped></style>
