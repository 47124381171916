<template>
  <!-- Page title -->
  <div class="d-flex flex-column w-100">
    <div class="page-title d-flex align-items-center bg-image py-5"
      style="background-image: url('assets/images/page-title-bg-13.jpg')">
      <div class="container page-title-container">
        <div class="row">
          <div class="col text-center">

            <h1 class="display-3 font-weight-800 text-white mb-1">
              Blog
            </h1>

            <div class="lead text-white-75 mb-3">
              Read Our Latest Blogs
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Breadcrumb -->
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="bg-danger py-4 mt-up50 rounded-xl shadow-lg">

          <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-light-2 px-md-4 mb-0">
              <li class="breadcrumb-item text-uppercase"><router-link to="/">Home</router-link></li>
              <li class="breadcrumb-item text-uppercase active" aria-current="page">blog</li>
            </ol>
          </nav>

        </div>
      </div>
    </div>
  </div>

  <!-- Content -->
  <div class="main-content">

    <div class="section">
      <div class="container">

        <!-- Blog list section -->
        <div class="row align-items-center mb-3">
          <div class="col-md-4 mb-4">
            <b v-if="tag" class="mr-2">Filtered by</b>
            <button v-if="tag" type="button" class="btn btn-round btn-sm btn-outline-danger m-0" @click="clearTag()">{{ tag }}<i class="fas fa-times ml-2 mr-0"></i></button>
          </div>
          <div class="col-md-4 offset-md-4 mb-4">
            <div class="input-group input-group-lg input-group-round">
              <div class="input-group-inner">
                <div class="input-group-prepend">
                  <span class="input-group-text input-group-icon"><i class="fas fa-search"></i></span>
                </div>
                <input type="text" class="form-control form-control-lg" placeholder="Search here..." v-model="search" @change="onSearch">
                <div class="input-focus-bg"></div>
              </div>
            </div>
          </div>

          <div class="col-12">

            <!-- No data -->
            <div v-if="posts.length===0" class="row">
              <div class="col text-center">
                <p class="h3">No available data</p>
              </div>
            </div>
            
            <div class="row blog-container">

              <!-- Grid item -->
              <div v-for="post in posts" :key="post.slug" class="col-md-6 col-lg-4 grid-item">
                <div class="card card-blog-entry border-0 plain-card">
                  <div class="card-blog-img hover-item hover-scale hover-uncaption">
                    <router-link :to="{ name: 'Post', query: { slug: post.slug }}">
                      <div class="hover-transition">
                        <div class="hover-down">
                          <i class="fas fa-link fa-2x hover-icon"></i>
                        </div>
                      </div>
                      <figure>
                        <img class="img-fluid" :src="post.imageUrl" :alt="post.imageAlt">
                      </figure>
                    </router-link>
                  </div>
                  <div class="card-body pt-0">
                    <div v-if="post.tags.length > 2" class="category-btn">
                      <a v-for="tag in post.tags.slice(0, 2)" :key="tag.slug" class="btn btn-round btn-sm btn-danger mr-1 text-white" @click.prevent="getPosts(tag.slug)">{{ tag.name }}</a>
                      <a class="btn btn-round btn-sm btn-danger mr-1 text-white"><i class="fas fa-ellipsis-h m-0"></i></a>
                    </div>
                    <div v-if="post.tags.length <= 2" class="category-btn">
                      <a v-for="tag in post.tags" :key="tag.slug" class="btn btn-round btn-sm btn-danger mr-1 text-white" @click.prevent="getPosts(tag.slug)">{{ tag.name }}</a>
                    </div>
                    <h5 class="font-weight-700 mt-3">
                      <router-link :to="{ name: 'Post', query: { slug: post.slug }}" class="text-dark-gray">{{ post.title }}</router-link>
                    </h5>
                    <ul class="meta-entry">
                      <li class="meta-entry-item">
                        <router-link :to="{ name: 'Post', query: { slug: post.slug }}">{{ publishd(post.published) }}</router-link>
                      </li>
                      <li class="meta-entry-item">
                        <router-link :to="{ name: 'Post', query: { slug: post.slug }}">{{ post.author.firstName }} {{ post.author.lastName }}</router-link>
                      </li>
                    </ul>
                    <p class="card-text">{{ truncate(post.summary) }}</p>
                  </div>
                  <div class="card-footer border-0 bg-transparent">
                    <div class="meta-item float-left mb-2">
                      <router-link :to="{ name: 'Post', query: { slug: post.slug }}"><i class="far fa-list-alt text-black-50"></i>Read more</router-link>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 offset-md-4 text-center">
            <button v-if="showLoadMore" type="button" class="btn btn-round btn-outline-danger mx-2 mx-lg-3 w-75" @click="getPosts()">Load more</button>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import axios from 'axios';
import moment from 'moment';
// import Butter from 'buttercms';
export default {
  data() {
    return {
      currentPage: 1,
      showLoadMore: false,
      tag: '',
      search: ''
    }
  },
  computed: {
    ...mapState([
      'posts'
    ])
  },
  methods: {
    ...mapMutations([
      'setPosts'
    ]),
    publishd(date) {
      return moment(date).format('DD MMM YYYY');
    },
    truncate(text) {
      if (text.length < 300) {
        return text;
      }
      
      return text.slice(0, 300) + '...';
    },
    getPosts(tag = null) {
      // const butter = Butter(process.env.VUE_APP_BUTTER_CMS_TOKEN);
      // const options = {
      //   page: 1,
      //   page_size: this.currentPage * 6
      // };
      // if (tag) {
      //   options['tag_slug'] = tag;
      //   this.tag = tag;
      //   this.$router.replace({name: 'Blog', query: {tag}});
      //   this.search = '';
      // }

      // butter.post.list(options)
      //   .then((res) => {
      //     console.log('posts: ', res.data);
      //     this.setPosts(res.data.data);
      //     if (!res.data.meta.next_page) {
      //       this.showLoadMore = false;
      //     } else {
      //       this.showLoadMore = true;
      //     }
      //     this.currentPage++;
      //   });
      let url = 'blog/posts?page=1&pageSize=' + this.currentPage * 6;
      if (tag) {
        url += '&tag=' + tag;
      }
      axios.get(url).then(res => {
        console.log('posts: ', res.data);
        this.setPosts(res.data.blogPosts);
        if (this.currentPage * 6 > res.data.totalPostCount) {
          this.showLoadMore = false;
        } else {
          this.showLoadMore = true;
        }

        this.currentPage++;
      }).catch(err => {
        console.log('posts error: ', err);
      })
    },
    onSearch(e) {
      this.currentPage = 1;
      if (!e.target.value) {
        this.getPosts();

        return;
      }
      
      // const butter = Butter(process.env.VUE_APP_BUTTER_CMS_TOKEN);
      // butter.post.search(e.target.value, {page: 1, page_size: this.currentPage * 6})
      //   .then((res) => {
      //     console.log('search: ', res.data);
      //     this.setPosts(res.data.data);
      //     if (!res.data.meta.next_page) {
      //       this.showLoadMore = false;
      //     } else {
      //       this.showLoadMore = true;
      //     }
      //     this.currentPage++;
      //   });
    },
    clearTag() {
      this.tag = '';
      this.$router.replace({name: 'Blog', query: {}});
      this.getPosts();
    }
  },
  created() {
    const tag = this.$route.query.tag;
    if (tag) {
      this.tag = tag;
      this.getPosts(tag);
    } else {
      this.getPosts();
    }
  }
}
</script>

<style lang="scss" scoped>
  .blog-container {
    
    .img-fluid {
      height: 300px;
      width: 100%;
      object-fit: cover;

      @media (max-width: 768px) {
        height: 200px;
      }
    }
  }
</style>