<template>
  <!-- Preloader -->
  <div v-if="loading" class="row preloader">
    <div class="col">
      <div class="preloader-wrapper">
        <div class="preloader-border"></div>
        <div class="preloader-line-mask">
          <div class="preloader-line"></div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="!loading" class="main-content p-0 pt-4">
    <div class="section text-center">
      <img :src="data?.customerImageUrl" alt="logo" class="logo-landing" />
    </div>
    <div class="section pb-4">
      <div class="container">
        <div class="row justify-content-center mb-4">
          <div class="col-md-10">
            <div class="text-center">
              <h3 class="font-weight-600">{{ data?.description }}</h3>
            </div>
          </div>
        </div>
        <div class="row d-md-none">
          <div class="col-12 mb-4">
            <div class="rounded-xl shadow bg-white py-4 pl-1 pr-4">
              <ul class="custom-list fa-ul mb-0">
                <li v-for="product in data?.products" :key="product.locationName"
                  @click="scrollToElement(product.locationName)">
                  <span class="fa-li">
                    <i class="fas fa-angle-right text-sky"></i>
                  </span>
                  {{ product.locationName }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-12">
            <div class="rounded-xl shadow-sm overflow-hidden">
              <GMapMap :center="{ lat: data?.customerCoordinates[1], lng: data?.customerCoordinates[0] }" :zoom="15">
                <GMapMarker :position="{ lat: data?.customerCoordinates[1], lng: data?.customerCoordinates[0] }"
                  @click="openMarker('Foundry')" @closeclick="openMarker('')">
                  <GMapInfoWindow :closeclick="true" :opened="openedMarkerName === 'Foundry'"
                    @closeclick="openMarker('')">
                    <h6 class="mb-0">Foundry</h6>
                  </GMapInfoWindow>
                </GMapMarker>
                <GMapMarker v-for="product in data?.products"
                  :position="{ lat: product.locationCoordinate.coordinates[1], lng: product.locationCoordinate.coordinates[0] }"
                  :icon="'/assets/images/parking_lot_maps.png'" :key="product.locationName"
                  @mouseover="openMarker(product.locationName)" @mouseout="openMarker('')">
                  <GMapInfoWindow :closeclick="true" :opened="product.locationName === openedMarkerName"
                    @closeclick="openMarker('')">
                    <h6 class="mb-0">{{ product.locationName }}</h6>
                  </GMapInfoWindow>
                </GMapMarker>
              </GMapMap>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div v-for="(product, index) in data?.products" :key="'product_' + index" class="col-md-4 mb-4"
            :ref="product.name" @mouseover="openMarker(product.locationName)" @mouseout="openMarker('')">
            <div class="bg-light rounded-xl shadow-sm border overflow-hidden"
              :class="openedMarkerName === product.locationName ? 'border-info' : 'border-dark'">
              <div class="px-3 py-4">
                <h5 class="text-center text-primary">{{ product.locationName }}</h5>
                <p class="text-center">{{ product.locationAddress }}, {{ product.locationCity }}, {{
                    product.locationState
                }} {{ product.locationZip }}</p>
                <div v-for="(offer, oIndex) in product.offers" :key="'product_' + index + '_offer_' + oIndex"
                  class="row mt-4">
                  <div class="col-8">
                    <p class="text-primary font-weight-600 mb-1">{{ offer.productName }}</p>
                    <h6>${{ offer.discountPrice }} <span class="text-danger"
                        style="text-decoration-line: line-through;">${{ offer.regularPrice }}</span></h6>
                  </div>
                  <div class="col-4 text-right">
                    <button class="btn btn-sm btn-round btn-outline-primary w-100"
                      @click="getRequest(product, offer)">Request</button>
                  </div>
                </div>
                <div class="mb-3">
                  <h6 class="text-center">About Facility</h6>
                  <div v-for="(about, aIndex) in product.locationAbout" :key="'product_' + index + '_about_' + aIndex">
                    {{
                        about
                    }}</div>
                </div>
                <div>
                  <h6 class="text-center">Directions</h6>
                  <ul>
                    <li v-for="(direction, dIndex) of product.locationDirectionLinks"
                      :key="product.address + '_direction_' + dIndex"><a :href="direction.link" target="_blank">{{
                          direction.name
                      }}</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="modalRequest" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content rounded-xl">
        <div class="modal-body p-0">

          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"></span>
          </button>

          <div class="px-4 px-md-5 py-5">

            <h4 class="mb-4 text-center font-weight-700">Parker Information</h4>

            <form @submit.prevent="onSubmit()">

              <div class="form-row">

                <div class="col-lg-6">
                  <div class="pl-lg-1">
                    <label class="ml-3"><span class="text-danger">*</span>Facility</label>
                    <div class="input-group input-group-lg input-group-round mb-4">
                      <div class="input-group-inner">
                        <div class="input-group-prepend">
                          <span class="input-group-text input-group-icon"><i class="fas fa-list-ul"></i></span>
                        </div>
                        <select v-model="locationId" class="custom-select custom-select-lg">
                          <option disabled>Choose Facility</option>
                          <option v-for="(product, index) in data?.products" :key="'Facility_' + index"
                            :value="product.locationId" :selected="product.locationId === locationId">
                            {{ product.locationName }}</option>
                        </select>
                        <div class="input-focus-bg"></div>
                      </div>
                      <div v-if="v$.locationId.$error" class="text-danger ml-4">{{
                          v$.locationId.$errors[0].$message
                      }}</div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="pl-lg-1">
                    <label class="ml-3"><span class="text-danger">*</span>Product</label>
                    <div class="input-group input-group-lg input-group-round mb-4">
                      <div class="input-group-inner">
                        <div class="input-group-prepend">
                          <span class="input-group-text input-group-icon"><i class="fas fa-list-ul"></i></span>
                        </div>
                        <select v-model="productId" class="custom-select custom-select-lg">
                          <option disabled>Choose Product</option>
                          <option v-for="(offer, index) in selectedFacility?.offers" :key="'Product_' + index"
                            :value="offer.productId" :selected="offer.productId === productId">{{
                                offer.productName
                            }}</option>
                        </select>
                        <div class="input-focus-bg"></div>
                      </div>
                      <div v-if="v$.productId.$error" class="text-danger ml-4">{{
                          v$.productId.$errors[0].$message
                      }}</div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="pr-lg-1">
                    <label class="ml-3"><span class="text-danger">*</span>First Name</label>
                    <div class="input-group input-group-lg input-group-round mb-4">
                      <div class="input-group-inner">
                        <div class="input-group-prepend">
                          <span class="input-group-text input-group-icon"><i class="far fa-user"></i></span>
                        </div>
                        <input v-model="firstName" @blur="v$.firstName.$touch()" type="text"
                          class="form-control form-control-lg" placeholder="First Name">
                        <div class="input-focus-bg"></div>
                      </div>
                      <div v-if="v$.firstName.$error" class="text-danger ml-4">{{
                          v$.firstName.$errors[0].$message
                      }}</div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="pr-lg-1">
                    <label class="ml-3"><span class="text-danger">*</span>Last Name</label>
                    <div class="input-group input-group-lg input-group-round mb-4">
                      <div class="input-group-inner">
                        <div class="input-group-prepend">
                          <span class="input-group-text input-group-icon"><i class="far fa-user"></i></span>
                        </div>
                        <input v-model="lastName" @blur="v$.lastName.$touch()" type="text"
                          class="form-control form-control-lg" placeholder="Last Name">
                        <div class="input-focus-bg"></div>
                      </div>
                      <div v-if="v$.lastName.$error" class="text-danger ml-4">{{
                          v$.lastName.$errors[0].$message
                      }}</div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="pl-lg-1">
                    <label class="ml-3"><span class="text-danger">*</span>Email</label>
                    <div class="input-group input-group-lg input-group-round mb-4">
                      <div class="input-group-inner">
                        <div class="input-group-prepend">
                          <span class="input-group-text input-group-icon"><i class="far fa-envelope"></i></span>
                        </div>
                        <input v-model="emailAddress" @blur="v$.emailAddress.$touch()" type="email"
                          class="form-control form-control-lg" placeholder="Email">
                        <div class="input-focus-bg"></div>
                      </div>
                      <div v-if="v$.emailAddress.$error" class="text-danger ml-4">{{
                          v$.emailAddress.$errors[0].$message
                      }}</div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="pr-lg-1">
                    <label class="ml-3"><span class="text-danger">*</span>Phone</label>
                    <div class="row">
                      <div class="col">
                        <div class="input-group input-group-lg input-group-round mb-4">
                          <div class="input-group-inner">
                            <div class="input-group-prepend">
                              <span class="input-group-text input-group-icon"><i class="fas fa-mobile-alt"></i></span>
                            </div>
                            <input v-model="phoneNumber" @blur="v$.phoneNumber.$touch()" type="tel"
                              class="form-control form-control-lg" placeholder="Phone (XXX-XXX-XXXX)">
                            <div class="input-focus-bg"></div>
                          </div>
                          <div v-if="v$.phoneNumber.$error" class="text-danger ml-4">{{
                              v$.phoneNumber.$errors[0].$message
                          }}</div>
                        </div>
                      </div>
                      <div class="col-auto pl-0">
                        <button type="button" class="btn btn-indigo btn-sm rounded-circle px-3 py-2 mt-2"
                          data-toggle="tooltip" data-placement="top"
                          title="Make sure this is the phone number of your mobile device as your digital credential will be linked to it.">
                          <i class="fas fa-exclamation m-0"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div v-if="errorMsg" class="col-12 text-danger text-center">{{ errorMsg }}</div>
              </div>

              <div class="form-row justify-content-end mt-4">
                <div class="col-4">
                  <button type="submit" class="btn btn-lg btn-round btn-indigo btn-block btn-gray-shadow mb-2">Submit
                    <div v-if="saving" class="spinner-border spinner-border-sm text-light" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </button>
                </div>
                <div class="col-4">
                  <button type="submit" class="btn btn-lg btn-round btn-danger btn-block btn-gray-shadow mb-2"
                    data-dismiss="modal">Cancel</button>
                </div>

              </div>

            </form>

            <div class="pt-3">
              <h5>Process:</h5>
              <p>We will reach out to you within the next business day to confirm product, answer any questions, and
                gather payment information. After we will send you instructions to digitally access the parking
                facility.</p>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import useVuelidate from '@vuelidate/core'
import { helpers, required, email } from '@vuelidate/validators'

const $ = window.$;

export default {
  data() {
    return {
      loading: false,
      data: null,
      openedMarkerName: '',
      selectedFacility: null,
      v$: useVuelidate(),
      locationId: null,
      productId: null,
      firstName: '',
      lastName: '',
      emailAddress: '',
      phoneNumber: '',
      saving: false,
      errorMsg: '',
    }
  },
  validations() {
    return {
      locationId: { required: helpers.withMessage('Please choose a facility', required) },
      productId: { required: helpers.withMessage('Please choose a product', required) },
      firstName: { required: helpers.withMessage('First Name is required', required) },
      lastName: { required: helpers.withMessage('Last Name is required', required) },
      emailAddress: { required: helpers.withMessage('Email is required', required), email: helpers.withMessage('Email is not valid', email) },
      phoneNumber: { required: helpers.withMessage('Phone is required', required), phone: helpers.withMessage('Phone is not valid', helpers.regex(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/)) }
    }
  },
  methods: {
    openMarker(name) {
      this.openedMarkerName = name;
    },
    getRequest(facility, location) {
      this.selectedFacility = facility;
      this.locationId = facility.locationId;
      this.productId = location.productId;
      $('#modalRequest').modal('show');
    },
    async onSubmit() {
      const result = await this.v$.$validate();
      if (!result) {
        return
      }

      this.saving = true;
      const { data: { id: landingPageId }, locationId, productId, firstName, lastName, emailAddress, phoneNumber } = this;
      const payload = {
        landingPageId,
        locationId,
        productId,
        firstName,
        lastName,
        emailAddress,
        phoneNumber
      }
      axios.post('landingpagerequest', payload).then(() => {
        this.saving = false;

        $('#modalRequest').modal('hide');
      }, err => {
        this.saving = false;

        this.errorMsg = err.error.responseStatus.message
      })
    },
    scrollToElement(id) {
      const el = this.$refs[id];
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    }
  },
  mounted() {
    if (!$) {
      return;
    }

    $('#modalRequest').on('hidden.bs.modal', () => {
      this.selectedFacility = null;
      this.locationId = null;
      this.productId = null;
      this.firstName = '';
      this.lastName = '';
      this.emailAddress = '';
      this.phoneNumber = '';
      this.v$.$reset();
    });

    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    })
  },
  created() {
    this.loading = true;

    const url = 'website/landingPage?pageName=deloitte-cincinnati';
    axios.get(url).then(res => {
      this.data = res.data;

      this.loading = false;
    }).catch(err => {
      console.log('deloitte error: ', err);
      this.data = null;

      this.loading = false;
    })
  }
};
</script>

<style lang="scss" scoped>
.preloader {
  position: absolute;
  left: calc(50% - 50px);
  top: calc(50% - 50px);
}

.logo-landing {
  width: 500px;

  @media (max-width: 768px) {
    width: 300px;
  }
}

.vue-map-container {
  height: 300px;
}

.img-map {
  width: 100%;
}
</style>
