<template>
    <header class="navik-header header-shadow">
      <div class="container">
        <div class="navik-header-container">
  
          <div class="logo" data-mobile-logo="assets/images/logo.png"
            data-sticky-logo="assets/images/logo.png">
            <img src="assets/images/logo.png" alt="Mobile Infrastructure Logo">
          </div>
  
        </div>
      </div>
      <div class="header-shadow-wrapper"></div>
    </header>
  </template>
  
  <script>
  import { mapState } from 'vuex';
  const $ = window.$;
  export default {
    name: 'm-plainheader',
    data() {
      return {
        path: '/'
      }
    },
    computed: {
      ...mapState([
        'showBlog'
      ])
    },
    methods: {
      hideMobileMenu() {
        const menu = this.$el.querySelector('.burger-menu');
        if (menu.classList.contains('menu-open')) {
          const ul = this.$el.querySelector('ul');
          ul.style.display = 'none';
          menu.classList.remove('menu-open');
        }
      },
      stickyNav() {
        const scrollTop = $(window).scrollTop(),
          noSticky = $('.no-sticky'),
          viewportSm = $('.viewport-sm'),
          viewportLg = $('.viewport-lg'),
          viewportLgBody = viewportLg.parent('#app').parent('body'),
          navikHeader = $('.navik-header'),
          navikHeaderHeight = navikHeader.height();
  
        if (scrollTop > navikHeaderHeight) {
          navikHeader.addClass('sticky');
          viewportLgBody.css("margin-top", navikHeaderHeight);
          viewportLg.css("margin-top", -navikHeaderHeight);
        } else {
          navikHeader.removeClass('sticky');
          viewportLgBody.add(viewportLg).css("margin-top", "0");
        }
  
        noSticky.removeClass('sticky');
        viewportSm.removeClass('sticky');      
      }
    },
    watch:{
      $route(to) {
        this.path = to.path
      }
    },
    mounted() {
      if (!$) {
        return;
      }
  
   
      
      /* ========== Window resize ========== */
      $(window).on("resize", () => {
        if ($(window).width() < 1280) {
          $('.navik-header').removeClass('viewport-lg');
          $('.navik-header').addClass('viewport-sm');
        } else {
          $('.navik-header').removeClass('viewport-sm');
          $('.navik-header').addClass('viewport-lg');
        }
  
        this.stickyNav();
      }).resize();
  
      /* ========== Sticky on scroll ========== */
      $(window).on("scroll", () => {
        this.stickyNav();
      }).scroll();
    }
  }
  </script>
  