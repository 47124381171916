<template>
  <!-- Page title -->
  <div v-if="post" class="d-flex flex-column w-100">
    <div class="page-title page-header-block-height d-flex align-items-center bg-image py-5"
      :style="'background-image: url(' + post?.imageUrl + ')'">
      <div class="container page-title-container">
        <div class="row py-5">
          <div class="col-lg-8 offset-lg-2 text-center">

            <div class="mb-3">
              <router-link :to="{ name: 'Blog', query: { tag: tag.slug }}" v-for="tag in post?.tags" :key="tag.slug" class="badge badge-pill badge-danger mr-1 mb-1 text-white">{{ tag.name }}</router-link>
            </div>

            <h1 class="display-4 font-weight-800 text-white mb-3">
              {{ post?.title }}
            </h1>

            <nav v-if="post" aria-label="breadcrumb">
              <ol class="breadcrumb breadcrumb-light-2 justify-content-center mb-0">
                <li class="breadcrumb-item text-uppercase"><a>{{ publishd(post?.published) }}</a></li>
                <li class="breadcrumb-item text-uppercase"><a>{{ post?.author.firstName }} {{ post?.author.lastName }}</a></li>
              </ol>
            </nav>

          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Content -->
  <div v-if="post" class="main-content">

    <!-- Blog content section -->
    <div class="section">
      <div class="container">
        <div class="row mt-3">
          <div class="col-lg-10 offset-lg-1">
            
            <div v-html="post?.bodyHtml" class="post-body"></div>

            <!-- Share this -->
            <div v-if="post" class="d-flex align-items-center justify-content-center">

              <span class="badge badge-pill badge-light">Share This</span>

              <div class="d-flex ml-2">
                <ShareNetwork
                  network="facebook"
                  :url="url"
                  :title="post?.title"
                  :description="post?.summary"
                >
                  <i class="fab fa-facebook-f"></i>
                </ShareNetwork>
                <ShareNetwork
                  network="twitter"
                  :url="url"
                  :title="post?.title"
                  :description="post?.summary"
                >
                  <i class="fab fa-twitter"></i>
                </ShareNetwork>
                <ShareNetwork
                  network="linkedin"
                  :url="url"
                  :title="post?.title"
                  :description="post?.summary"
                >
                  <i class="fab fa-linkedin-in"></i>
                </ShareNetwork>
                <ShareNetwork
                  network="pinterest"
                  :url="url"
                  :title="post?.title"
                  :description="post?.summary"
                >
                  <i class="fab fa-pinterest-p"></i>
                </ShareNetwork>
                <ShareNetwork
                  network="telegram"
                  :url="url"
                  :title="post?.title"
                  :description="post?.summary"
                >
                  <i class="fas fa-paper-plane"></i>
                </ShareNetwork>
              </div>

            </div>


          </div>
        </div>
      </div>
    </div>

    <!-- Related news section -->
    <!-- <div v-if="relatedPosts.length" class="section pt-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-10 offset-lg-1">

            <div class="row align-items-center mb-5">

              <div class="col-md-6 mb-3 mb-md-0">
                <h4 class="section-title-3 text-left font-weight-700 mb-md-0">Related Articles</h4>
              </div>

              <div class="col-md-6">
                <div class="text-md-right">
                  <router-link to="/blog" class="btn btn-round btn-danger mb-0">View all articles</router-link>
                </div>
              </div>

            </div>

            <div class="row">
              <div class="col">
                <div class="carousel-component">
                  <div class="carousel-component-inner owl-carousel owl-theme">

                    <div v-for="p in relatedPosts" :key="p.slug" class="carousel-component-item">
                      <div class="card card-blog-entry border-0 plain-card mb-3">
                        <div class="card-blog-img hover-item hover-scale hover-uncaption">
                          <router-link :to="{ name: 'Post', query: { slug: p.slug }}">
                            <div class="hover-transition">
                              <div class="hover-down">
                                <i class="fas fa-link fa-2x hover-icon"></i>
                              </div>
                            </div>
                            <figure>
                              <img class="img-fluid"  :src="p.imageUrl" :alt="p.imageAlt">
                            </figure>
                          </router-link>
                        </div>
                        <div class="card-body pt-0">
                          <div v-if="p.tags.length > 2" class="category-btn">
                            <router-link :to="{ name: 'Blog', query: { tag: tag.slug }}" v-for="tag in p.tags.slice(0, 2)" :key="tag.slug" class="btn btn-round btn-sm btn-danger mr-1 text-white">{{ tag.name }}</router-link>
                            <a class="btn btn-round btn-sm btn-danger mr-1 text-white"><i class="fas fa-ellipsis-h m-0"></i></a>
                          </div>
                          <div v-if="p.tags.length <= 2" class="category-btn">
                            <router-link :to="{ name: 'Blog', query: { tag: tag.slug }}" v-for="tag in p.tags" :key="tag.slug" class="btn btn-round btn-sm btn-danger mr-1 text-white">{{ tag.name }}</router-link>
                          </div>
                          <h5 class="font-weight-700 mt-3">
                            <router-link :to="{ name: 'Post', query: { slug: p.slug }}" class="text-dark-gray">{{ p.title }}</router-link>
                          </h5>
                          <ul class="meta-entry">
                            <li class="meta-entry-item">
                              <router-link :to="{ name: 'Post', query: { slug: p.slug }}">{{ publishd(p.published) }}</router-link>
                            </li>
                            <li class="meta-entry-item">
                              <router-link :to="{ name: 'Post', query: { slug: p.slug }}">{{ p.author.firstName }} {{ p.author.lastName }}</router-link>
                            </li>
                          </ul>
                          <p class="card-text">{{ p.summary }}</p>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div> -->

  </div>

  <!-- Preloader -->
  <div v-if="!post" class="preloader-container">
    <div class="preloader-wrapper">
      <div class="preloader-border"></div>
      <div class="preloader-line-mask">
        <div class="preloader-line"></div>
      </div>
    </div>
  </div>
</template>

<script>
// import { nextTick } from 'vue';
import { mapState } from 'vuex';
import moment from 'moment';
// import Butter from 'buttercms';

var $ = window.$;

export default {
  data() {
    return {
      post: null,
      url: location.toString(),
      relatedPosts: [],
    }
  },
  computed: {
    ...mapState([
      'posts'
    ]),
  },
  methods: {
    getPost() {
      const slug = this.$route.query.slug;
      if (!slug) {
        return;
      }

      if (this.posts.length > 0) {
        this.post = this.posts.find(x => x.slug === slug);
      } else {
        // const butter = Butter(process.env.VUE_APP_BUTTER_CMS_TOKEN);
        // butter.post.retrieve(slug)
        //   .then((res) => {
        //     this.post = res.data.data;
        //   })
        //   .catch((err) => {
        //     console.log(err)
        //   });
        this.$router.replace({ name: 'Blog' })
      }
    },
    publishd(date) {
      return moment(date).format('DD MMM YYYY');
    },
    initCarousel() {
      $('.carousel-component').each(function() {
        const carouselComponent = $(this).find('.carousel-component-inner'),
            carouselComponentItem = $(this).find('.carousel-component-item'),
            carouselCustomNav = $(this).next('.carousel-custom-nav'),
            customNavPrev = carouselCustomNav.find('.carousel-control-prev'),
            customNavNext = carouselCustomNav.find('.carousel-control-next');

        $(this).css({
          "margin-right": -15,
          "margin-left": -15
        });

        carouselComponentItem.css({
          "padding-right": 15,
          "padding-left": 15
        });

        carouselComponent.owlCarousel({
          autoplay: true,
          autoplayHoverPause: true,
          smartSpeed: 500,
          loop: true,
          dots: true,
          nav: false,
          responsive: {
            0: {
              items: 1
            },
            576: {
              items: 1
            },
            768: {
              items: 2
            },
            992: {
              items: 3
            },
            1280: {
              items: 3
            }
          }
        });

        customNavPrev.on("click", function (e) {
          e.preventDefault();
          carouselComponent.trigger('prev.owl.carousel', [500]);
        });

        customNavNext.on("click", function (e) {
          e.preventDefault();
          carouselComponent.trigger('next.owl.carousel', [500]);
        });
      });
    }
  },
  watch: {
    $route() {
      this.getPost();
    },
    post() {
      document.title = this.post.seoTitle + ' - Mobile Infrastructure';
      document.querySelector('meta[name="description"]').setAttribute('content', this.post.metaDescription);

      // this.relatedPosts = [];
      // const tmp = [];
      // const butter = Butter(process.env.VUE_APP_BUTTER_CMS_TOKEN);
      // this.post.tags.forEach((tag, index) => {
      //   butter.post.list({"tag_slug": tag.slug})
      //     .then((res) => {
      //       res.data.data.forEach(p => {
      //         if (this.post.slug === p.slug) {
      //           return;
      //         }

      //         const id = tmp.findIndex(x => x.slug === p.slug);
      //         if (id === -1) {
      //           tmp.push(p);
      //         }
      //       });

      //       if (index === this.post.tags.length - 1) {
      //         setTimeout(() => {
      //           this.relatedPosts = tmp;
                
      //           nextTick(() => {
      //             this.initCarousel();
      //           });                  
      //         }, 500);
      //       }
      //     }).catch((err) => {
      //       console.log(err)
      //     });
      // });
    }
  },
  created() {
    this.getPost();
  },
  unmounted() {
    document.querySelector('meta[name="description"]').setAttribute('content', '');
  }
}
</script>

<style lang="scss" scoped>
  .page-header-block-height {
    @media (min-width: 768px) {
      min-height: 500px !important;
    }
  }
  .post-body {
    ::v-deep img {
      max-width: 100%;
      height: auto;
    }
  }
  .share-network-facebook,
  .share-network-twitter,
  .share-network-linkedin,
  .share-network-pinterest,
  .share-network-telegram {
    width: 40px;
    height: 40px;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    color: #1c2833 !important;
  }
  .carousel-component {
    .img-fluid {
      height: 300px;
      width: 100%;
      object-fit: cover;

      @media (max-width: 768px) {
        height: 200px;
      }
    }
  }
</style>