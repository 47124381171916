<template>
  <!-- Page title -->
  <div class="w-100">
    <div class="d-flex flex-column w-100">
      <div
        class="page-title d-flex align-items-center bg-image py-5"
        style="background-image: url('assets/images/faq-page-title.jpg')"
      >
        <div class="container page-title-container">
          <div class="row py-5">
            <div class="col text-center">

              <h1 class="display-4 font-weight-800 text-white mb-4">
                Terms of use
              </h1>              

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Content -->
  <div class="main-content py-0">
    <div class="section py-5">
      <div class="container">
        <div class="row justify-content-center my-5">
          <div class="col-md-10">
            <p>Welcome to the website of Mobile Infrastructure Corporation and/or its designees and affiliates ("Company," "We," "Us," or "Our"). The following terms and conditions, together with any documents they expressly incorporate by reference (collectively, these "Terms of Use"), govern your access to and use of the website located at mobileit.com, including any content, functionality, services offered on or through the website located at mobileit.com (the "Website").</p>
            <p class="font-weight-bold">THIS DOCUMENT CONTAINS VERY IMPORTANT INFORMATION REGARDING YOUR RIGHTS AND OBLIGATIONS, AS WELL AS CONDITIONS, LIMITATIONS, AND EXCLUSIONS THAT MIGHT APPLY TO YOU. PLEASE READ IT CAREFULLY.</p>
            <p class="font-weight-bold">THESE TERMS REQUIRE THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS.</p>
            <p>By using the Website, you accept, and agree to be bound and abide by, these Terms of Use and our Privacy Policy, found at <router-link to="/privacy-policy">HERE</router-link> ("Privacy Policy"), which is incorporated herein by reference. If you do not want to agree to these Terms of Use or the Privacy Policy, you must not access or use the Website.</p>
            <p>This Website is offered and available to users who are 18 years of age or older. By using this Website, you represent and warrant that you are of legal age to and have the requisite capacity and authority to form a binding contract with Company. If you do not meet these requirements, you must not access or use the Website.</p>
            
            <br />
            <h5>1. Changes to the Terms of Use</h5>
            <p>We may revise and update these Terms of Use from time to time in our sole discretion. All changes are effective immediately when we post them. Your continued use of the Website following the posting of revised Terms of Use means that you accept and agree to the changes. You are expected to check this page frequently so you are aware of any changes, as they are binding on you.</p>

            <br />
            <h5>2. Accessing the Website and Account Security</h5>
            <p>We reserve the right to withdraw or amend this Website, and any service or material we provide on the Website, in our sole discretion without notice. We will not be liable if, for any reason, all or any part of the Website is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Website, or the entire Website.</p>
            <p>You are responsible for:</p>
            <ul>
              <li>Making all arrangements necessary for you to have access to the Website.</li>
              <li>Ensuring that all persons who access the Website through an internet connection which you share are aware of these Terms of Use and comply with them.</li>
            </ul>
            <p>To access the Website or some of the resources it offers, you may be asked to provide certain registration details or other information. It is a condition of your use of the Website that all the information you provide on the Website is correct, current, and complete. You agree that all information you provide to register with this Website or otherwise, including, but not limited to, through the use of any interactive features on the Website, is governed by our Privacy Policy, and you consent to all actions we take with respect to your information consistent with our Privacy Policy.</p>
            <p>If you choose, or are provided with, a user name, password, or any other piece of information as part of our security procedures, you must treat such information as confidential, and you must not disclose it to any other person or entity. You also acknowledge that your account is personal to you and agree not to provide any other person with access to this Website or portions of it using your user name, password or other security information. You agree to notify us immediately of any unauthorized access to or use of your user name or password or any other breach of security. You also agree to ensure that you exit from your account at the end of each session. You should use particular caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information.</p>
            <p>We have the right to disable any user name, password or other identifier, whether chosen by you or provided by us, at any time in our sole discretion for any or no reason, including if, in our opinion, you have violated any provision of these Terms of Use.</p>

            <br />
            <h5>3. Intellectual Property Rights & Use of this website</h5>
            <p>The Website and its entire contents, features and functionality (including but not limited to all information, software, text, displays, images, video, audio, colors, background, objects, "look and feel," layout, and the design, selection, and arrangement thereof), are owned by Company or its licensors and are protected by United States and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws. All rights reserved. You are responsible for obeying all applicable copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws and these Terms of Use</p>
            <p>These Terms of Use permit you to use the Website for the limited personal purpose for which Company provides the Website, namely for information relating to Company and its business (the "Purpose"). You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our Website, except as follows:</p>
            <ul>
              <li>Your computer or mobile device may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.</li>
              <li>You may store files that are automatically cached by your Web browser for display enhancement purposes.</li>
              <li>You may print or download one copy of a reasonable number of pages of the Website for internal business purposes relating to the Purpose only and not for further reproduction, publication or distribution.</li>
              <li>If we provide desktop, mobile, or other applications for download, you may download a single copy to your computer or mobile device solely for internal business purposes relating to the Purpose, provided you agree to be bound by our end user license agreement for such applications.</li>
            </ul>
            <p>You must not access or use any part of the Website or any services or materials available through the Website for any purpose other than the Purpose. Without limiting the generality of the previous stated prohibition, you may not:</p>
            <ul>
              <li>Modify copies of any materials from this site.</li>
              <li>Use any illustrations, photographs, video or audio sequences, or any graphics separately from the accompanying text.</li>
              <li>Delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from this Website.</li>
              <li>Use the Website or its contents for competitive or benchmarking purposes.</li>
            </ul>
            <p>If you print, copy, modify, download, or otherwise use or provide any other person with access to any part of the Website in breach of the Terms of Use, your right to use the Website will cease immediately and you must, at our option, return or destroy any copies of the materials you have made. No right, title or interest in or to the Website or any content on the Website is transferred to you, and all rights not expressly granted are reserved by Company or its licensors. Any use of the Website not expressly permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark and other laws.</p>

            <br />
            <h5>4. Trademarks</h5>
            <p>The trademarks, tradenames, logos, and other designations of origin appearing on the Website, and the logos associated therewith are trademarks of Company. In addition, the colors, background, objects, "look and feel," layout, and the design, selection, combination and arrangement thereof appearing on the Website constitute the trade dress of Company. All other trademarks and company names or logos used in this Website are the property of either their respective owners or Company, as applicable. No permission or license (whether express or implied) is hereby granted regarding the use of any such trademarks, trade dress, product or service names, logos or titles, and such use may constitute infringement of the holder's rights.</p>
            
            <br />
            <h5>5. Prohibited Uses</h5>
            <p>You may use the Website only for lawful purposes and in accordance with these Terms of Use. Without limiting the generality of the foregoing, you agree not to</p>
            <ul>
              <li>Use the Website in any way that violates any applicable federal, state, local or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries);</li>
              <li>Decompile, reverse engineer, disassemble, rent, lease, loan, sell, sublicense, or create derivative works from the Website or the material contained on it;</li>
              <li>Impersonate or attempt to impersonate Company, employee of Company, another user or any other person or entity (including, without limitation, by using e-mail addresses associated with any of the foregoing).</li>
              <li>Use any network monitoring or discovery software to determine the site architecture, including but not limited to the Website's "look and feel," and trade dress or extract information about usage or users.</li>
              <li>Use any robot, spider, or other automatic or manual device or process to monitor or copy the Website, the Website's design, architecture, arrangement, or "look and feel," and trade dress or the material contained on it other than as expressly permitted in these Terms of Use.</li>
              <li>Use any device, software, or routine that interferes with the proper working of the Website.</li>
              <li>Frame this Website or link to a page other than the home page without our express written permission.</li>
              <li>Introduce any viruses, trojan horses, worms, logic bombs or other material which is malicious or technologically harmful.</li>
              <li>Attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the Website, the server on which the Website is stored, or any server, computer or database connected to the Website.</li>
              <li>Attack the Website via a denial-of-service attack or a distributed denial-of-service attack.</li>
              <li>Otherwise attempt to interfere with the proper working of the Website.</li>
            </ul>

            <br />
            <h5>6. Use of any hyperlink</h5>
            <p>Company is not responsible for the content of any other website, including any website through which you may have gained access to our Website or to which you may gain access from our Website. We do not accept any liability in connection with any such sites or links.</p>
            <p>Where we provide a hyperlink to a third party's website, we do so because we believe in good faith that such a website contains or may contain material which is relevant to that on our Website. Such a hyperlink does not signify that Company has reviewed or approves of the connected third party's website or its contents – indeed in certain instances a hyperlink may connect you to a third party's website containing views contrary to those expressed on our Website or otherwise held by Company.</p>
            
            <br />
            <h5>7. Information About You and Your Visits to the Website</h5>
            <p>All information we collect on this Website is subject to our Privacy Policy. By using the Website, you consent to all actions taken by us with respect to your information in compliance with the Privacy Policy.</p>
            
            <br />
            <h5>8. Changes to this website</h5>
            <p>The information, material, and content provided in the pages of this Website may be changed at any time without notice. Information, material, and content is not necessarily complete or up-to-date. Any of the information, material and content on the Website may be out of date at any given time, and we are under no obligation to update such information, material, and content.</p>
            
            <br />
            <h5>9. Disclaimer of Warranties</h5>
            <p>You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the Website will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.</p>
            <p>YOUR USE OF THE WEBSITE AND ITS CONTENT AND ANY ACTION TAKEN OR RELIANCE BASE ON THEM IS AT YOUR OWN RISK. THE WEBSITE AND ITS CONTENT ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE WEBSITE OR ITS CONTENT. WITHOUT LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE ASSOCIATED WITH COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE OR ITS CONTENT WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE WEBSITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE WEBSITE OR ITS CONTENT WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.</p>
            <p>COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.</p>
            
            <br />
            <h5>10. Limitation on Liability</h5>
            <p>IN NO EVENT WILL COMPANY, ITS AFFILIATES OR SUBSIDIARIES OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES, OR ANY GOODS OR SERVICES PURCHASED THROUGH THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.</p>

            <br />
            <h5>11. Indemnification</h5>
            <p>You agree to defend, indemnify and hold harmless Company, its affiliates, subsidiaries, licensors, and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms of Use or your use of the Website, including, but not limited to, any use of the Website's content and services other than as expressly authorized in these Terms of Use or your use of any information obtained from the Website.</p>
            
            <br />
            <h5>12. ArBITRATION & CLASS ACTION WAIVER</h5>
            <p>YOU AND COMPANY ARE AGREEING TO GIVE UP ANY RIGHTS TO LITIGATE CLAIMS IN A COURT OR BEFORE A JURY, OR TO PARTICIPATE IN A CLASS ACTION OR REPRESENTATIVE ACTION WITH RESPECT TO A CLAIM. OTHER RIGHTS THAT YOU WOULD HAVE IF YOU WENT TO COURT MAY ALSO BE UNAVAILABLE OR MAY BE LIMITED IN ARBITRATION.</p>
            <p>ANY CLAIM, DISPUTE OR CONTROVERSY (WHETHER IN CONTRACT, TORT OR OTHERWISE, WHETHER PRE-EXISTING, PRESENT OR FUTURE, AND INCLUDING STATUTORY, CONSUMER PROTECTION, COMMON LAW, INTENTIONAL TORT, INJUNCTIVE AND EQUITABLE CLAIMS) BETWEEN YOU AND US ARISING FROM OR RELATING IN ANY WAY TO YOUR PURCHASE OF PRODUCTS THROUGH THE WEBSITE, WILL BE RESOLVED EXCLUSIVELY AND FINALLY BY BINDING ARBITRATION.</p>
            <p>The arbitration will be administered in Cincinnati, Ohio by the American Arbitration Association ("AAA") in accordance with the Consumer Arbitration Rules (the "AAA Rules") then in effect, except as modified by this Section 12. (The AAA Rules are available at <a href="www.adr.org/arb_med" target="_blank">www.adr.org/arb_med</a> or by calling the AAA at <a href="tel:1-800-778-7879">1-800-778-7879</a>.) The Federal Arbitration Act will govern the interpretation and enforcement of this Section.</p>
            <p>The arbitrator will have exclusive authority to resolve any dispute relating to arbitrability and/or enforceability of this arbitration provision, including any unconscionability challenge or any other challenge that the arbitration provision or the agreement is void, voidable, or otherwise invalid. The arbitrator will be empowered to grant whatever relief would be available in court under law or in equity. Any award of the arbitrator(s) will be final and binding on each of the parties, and may be entered as a judgment in any court of competent jurisdiction. If you prevail on any claim that affords the prevailing party attorneys' fees, the arbitrator may award reasonable fees to you under the standards for fee shifting provided by law.</p>
            <p>You agree to an arbitration on an individual basis. In any dispute, NEITHER YOU NOR COMPANY WILL BE ENTITLED TO JOIN OR CONSOLIDATE CLAIMS BY OR AGAINST OTHER USERS IN COURT OR IN ARBITRATION OR OTHERWISE PARTICIPATE IN ANY CLAIM AS A CLASS REPRESENTATIVE, CLASS MEMBER OR IN A PRIVATE ATTORNEY GENERAL CAPACITY. The arbitral tribunal may not consolidate more than one person's claims, and may not otherwise preside over any form of a representative or class proceeding. The arbitral tribunal has no power to consider the enforceability of this class arbitration waiver and any challenge to the class arbitration waiver may only be raised in a court of competent jurisdiction.</p>
            <p>If any provision of this arbitration agreement is found unenforceable, the unenforceable provision will be severed and the remaining arbitration terms will be enforced.</p>
            
            <br />
            <h5>13. Waiver and Severability</h5>
            <p>No waiver of by Company of any term or condition set forth in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of Company to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision.</p>
            <p>In the event that any or any part of the terms contained in these Terms of Use shall be determined by any competent authority to be invalid, unlawful, or unenforceable to any extent, such terms shall to that extent be severed from the remaining terms which shall continue to be valid and enforceable to the fullest extent permitted by the applicable law.</p>

            <br />
            <h5>14. Entire Agreement</h5>
            <p>The Terms of Use and any documents linked to and/or incorporated herein by reference, including, without limitation, the Privacy, constitute the sole and entire agreement between you and Company with respect to the Website and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to the Website.</p>
            
            <br />
            <h5>15. Governing law</h5>
            <p>Your use of this Website and downloads from it, and the operation of these Terms of Use, shall be governed in accordance with the laws of the State of Ohio, without regard to conflict of law provisions. </p>
            
            <p>Effective: January 20, 2022.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>