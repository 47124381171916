<template>
  <!-- Preloader -->
  <div v-if="loading" class="preloader-container">
    <div class="preloader-wrapper">
      <div class="preloader-border"></div>
      <div class="preloader-line-mask">
        <div class="preloader-line"></div>
      </div>
    </div>
  </div>

  <m-header v-if="showHeaderFooter" />
  <m-plainheader v-if="showPlainHeaderFooter" />
  <article>
    <router-view />
  </article>
  <m-footer v-if="showHeaderFooter" />
  <m-plainfooter v-if="showPlainHeaderFooter" />
</template>

<script>
// import Butter from 'buttercms';
import { mapState, mapMutations } from 'vuex';
import axios from 'axios';

import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import PlainHeader from './components/PlainHeader.vue'
import PlainFooter from './components/PlainFooter.vue'
import jsonData from './data.json';

const ROUTES = [
  'Deloitte','Special Announcements'
]

export default {
  name: 'App',
  components: {
    [Header.name]: Header,
    [Footer.name]: Footer,
    [PlainHeader.name]: PlainHeader,
    [PlainFooter.name]: PlainFooter,
  },
  data() {
    return {
      loading: true
    }
  },
  computed: {
    ...mapState([
      'showBlog'
    ]),
    showHeaderFooter() {
      if (!this.$route.name) {
        return true;
      }
      
      return !ROUTES.includes(this.$route.name);
    },
    showPlainHeaderFooter(){
      return this.$route.name == 'Special Announcements';
      
    }
  },
  watch: {
    $route(to) {
      document.title = to.meta.title || 'Mobile Infrastructure';

      if (to.name === 'Blog' && !this.showBlog) {
        this.$router.replace({ name: 'Home' })
      }

      this.showHeaderFooter = !ROUTES.includes(to.path);
    }
  },
  methods: {
    ...mapMutations([
      'setWebsiteId',
      'setPosts',
      'setTeams',
      'setBoards',
      'setArticles',
      'setShowBlog',
      'setFaqs',
      'setSocials'
    ]),
    getSiteData() {
      const url = 'website/key?websitekey=mobileit.com';
      return axios.get(url);
    },
    getPosts() {
      return axios.get('blog/posts?page=1&pageSize=6');
    }
  },
  created() {
    this.loading = true;
    
    axios.all([this.getSiteData(), this.getPosts()]).then(res => {
      console.log('res0: ', res[0].data);
      this.setWebsiteId(res[0].data.id);
      this.setTeams(res[0].data.teamMembers);
      this.setBoards(res[0].data.boardMembers);
      this.setArticles(res[0].data.articles);
      this.setShowBlog(res[0].data.showBlog);
      this.setSocials(res[0].data.socialLinks);
      this.setFaqs(res[0].data.faqs);

      console.log('res1: ', res[1].data);
      this.setPosts(res[1].data.blogPosts);

      this.loading = false;
    }).catch(err => {
      console.log('key error: ', err);
      this.setWebsiteId(jsonData.id);
      this.setTeams(jsonData.teamMembers);
      this.setBoards(jsonData.boardMembers);
      this.setArticles(jsonData.articles);
      this.setShowBlog(jsonData.showBlog);
      this.setSocials(jsonData.socialLinks);
      this.setFaqs(jsonData.faqs);

      this.loading = false;
    });
  },
}
</script>

<style lang="scss">
  @import 'assets/scss/index.scss';
</style>
