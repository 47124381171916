<template>
  <!-- Page title -->
  <div class="w-100">

    <div class="d-flex flex-column w-100">
      <div class="page-title d-flex align-items-center bg-image py-5"
        style="background-image: url('assets/images/why-parking-bg.jpg')">
        <div class="container page-title-container">
          <div class="row">
            <div class="col text-center">

              <h1 class="display-3 font-weight-800 text-white mb-2">
                Why Parking?
              </h1>

              <div class="lead text-white-75 mb-4 mb-md-0">
                Our Assets Are Moats
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  
  <!-- Breadcrumb -->
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="bg-danger py-4 mt-up50 rounded-xl shadow-lg">

          <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-light-2 px-md-4 mb-0">
              <li class="breadcrumb-item text-uppercase"><router-link to="/">Home</router-link></li>
              <li class="breadcrumb-item text-uppercase active" aria-current="page">Why Parking</li>
            </ol>
          </nav>

        </div>
      </div>
    </div>
  </div>

  <!-- Content -->
  <div class="main-content">

    <div class="section">
      <div class="container">

        <div class="row mb-5">
          <div class="col-lg-8 offset-lg-2">
            <div class="px-4 px-lg-0">
              <blockquote class="blockquote text-center line-height-md mb-4">
                <p class="mb-0"><b>Our Assets Are Moats</b> - Their location is essential to their value and cannot be
                  replicated, they offer or can be produced at the lowest cost in their market and satisfy a small
                  percentage of overall market demand.</p>
              </blockquote>
            </div>
          </div>
        </div>
        
        <div class="row">
          <div class="col-md-8 offset-md-2 col-lg-12 offset-lg-0">
            <div class="row">

              <div class="col-md-6 pb-5">
                <div class="icon-info-1">
                  <a class="icon-info-link">
                    <div class="icon-element text-primary">
                      <i class="fas fa-user-friends fa-4x"></i>
                    </div>
                    <div class="icon-info-text pl-4">
                      <p class="text-dark mb-0" style="font-size: 16px">As more employees participate in a gig economy and use of public transit declines,
                        <b>TaaS will be required to have concern for employees' and customers' health</b>, putting
                        pressure on margins and creating opportunities for staging</p>
                    </div>
                  </a>
                </div>
              </div>

              <div class="col-md-6 pb-5">
                <div class="icon-info-1">
                  <a class="icon-info-link">
                    <div class="icon-element text-primary">
                      <i class="fas fa-sign-out-alt fa-5x"></i>
                    </div>
                    <div class="icon-info-text pl-4">
                      <p class="text-dark mb-0" style="font-size: 16px">Large, flat-floored car parks on the fringe of a CBD offers owners a
                        <b>redeployment opportunity</b> to use for Last Mile Warehousing or staging areas for TaaS</p>
                    </div>
                  </a>
                </div>
              </div>

              <div class="col-md-6 pb-5">
                <div class="icon-info-1">
                  <a class="icon-info-link">
                    <div class="icon-element text-primary">
                      <i class="far fa-building fa-6x"></i>
                    </div>
                    <div class="icon-info-text pl-4">
                      <p class="text-dark mb-0" style="font-size: 16px">Surface car parks historically have been transitioned to higher and
                        better uses following a recession, reducing available parking supply in the market<br>Parking is
                        no longer required to be built in new commercial or residential buildings, resulting an
                        <b>increased demand curve from urbanization against a lower available supply</b></p>
                    </div>
                  </a>
                </div>
              </div>

              <div class="col-md-6 pb-5">
                <div class="icon-info-1">
                  <a class="icon-info-link">
                    <div class="icon-element text-primary">
                      <i class="fas fa-folder fa-5x"></i>
                    </div>
                    <div class="icon-info-text pl-4">
                      <p class="text-dark mb-0" style="font-size: 16px">Main & Main <b>smaller CBD car parks will retain their value</b> as
                        urbanization continues an employees return to work and the supply curve shifts towards the left
                      </p>
                    </div>
                  </a>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="text-center">
              <router-link to="/parking-locations" class="btn btn-lg btn-danger"><i class="fas fa-long-arrow-alt-right"></i>Parking Locations</router-link>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>