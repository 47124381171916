import { createApp } from 'vue';
import VueSocialSharing from 'vue-social-sharing';
import axios from 'axios';
import VueClipboard from 'vue3-clipboard';
import VueGoogleMaps from '@fawmi/vue-google-maps'

import App from './App.vue';
import router from './router';
import store from './store';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

if (process.env.NODE_ENV === 'production') {
  console.log = function () {};
}

createApp(App)
  .use(VueSocialSharing)  
  .use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true,
  })
  .use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyC-qSmdD28C13SKVDnY5QTn6XsBjUw4ZTg',
    }
  })
  .use(router)
  .use(store)
  .mount('#app')
