<template>


  <!-- Content -->
  <div class="main-content">
    <div class="section bg-white">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10">
            <h2 class="text-center display-4 font-weight-800 my-4 mt-md-0">
              Special Announcements
            </h2>
          </div>
          <div class="col-lg-8">
            <img src="assets/images/announcement.png" width="100%" />
            <h5 class="text-center display-6 font-weight-600 my-4 mt-md-0">
              Fifth Wall Acquisition Corp. III (NASDAQ: FWAC), a special purpose
              acquisition company (SPAC) and Mobile Infrastructure Corp. jointly
              announced that they have entered into a definitive merger
              agreement.
            </h5>
          </div>
          <div class="col-lg-9">
              <ul class="custom-list fa-ul">
                <li class="lead mb-2">
                      <span class="fa-li">
                          <i class="fas fa-link text-danger"></i>
                      </span>
                  <a href="https://youtu.be/JgVzFmY0W-U"
                     target="_blank">MIC FAQ (video) </a>
                </li>
                <li class="lead mb-2">
                      <span class="fa-li">
                          <i class="fas fa-link text-danger"></i>
                      </span>
                  <a href="https://mobileit.com/assets/pdf/MIC%20Update%20FAQ%20July%202023.pdf"
                     target="_blank">MIC Update FAQ July 2023 </a>
                </li>
                  <li class="lead mb-2">
                      <span class="fa-li">
                          <i class="fas fa-link text-danger"></i>
                      </span>
                      <a href="https://www.fwac3.com/video/fwac-mic-videos"
                         target="_blank">FWAC & MIC - Videos - FWACIII -</a>
                  </li>
                  <li class="lead mb-2">
                      <span class="fa-li">
                          <i class="fas fa-link text-danger"></i>
                      </span>
                      <a href="https://cms.inigma.ai/artilce/uploads/63c95395c46dacd2b3704b5f.pdf"
                         target="_blank">Investor Presentation</a>
                  </li>
                  <li class="lead mb-2">
                      <span class="fa-li">
                          <i class="fas fa-link text-danger"></i>
                      </span>
                      <a href="	https://cms.inigma.ai/artilce/uploads/63adfed47b09da89ef9d9434.pdf"
                         target="_blank">MIC Merger FAQ</a>
                  </li>
                  <li class="lead mb-2">
                      <span class="fa-li">
                          <i class="fas fa-link text-danger"></i>
                      </span>
                      <a href="https://www.businesswire.com/news/home/20221213005998/en/Mobile-Infrastructure-Corporation-To-Be-Publicly-Listed-In-Merger-With-Fifth-Wall-Acquisition-Corp.-III"
                         target="_blank">
                          Mobile Infrastructure Corporation To Be Publicly Listed In
                          Merger With Fifth Wall Acquisition Corp. III
                      </a>
                  </li>
                  <li class="lead mb-2">
                      <span class="fa-li">
                          <i class="fas fa-link text-danger"></i>
                      </span>
                      <a href="https://cms.inigma.ai/artilce/uploads/63990a5413003c9c379549f8.pdf"
                         target="_blank">MIC Merger Presentation</a>
                  </li>
              </ul>
          </div>
        </div>
      </div>
    </div>
</div>
   
</template>

<script>

</script>

<style lang="scss" scoped>
.page-title {
  &::after {
    background-color: rgb(238 62 59 / 85%);
  }
}

.media {
  .img-fluid {
    width: 100px;
    height: 100px;

    @media (max-width: 768px) {
      width: 80px;
      height: 80px;
    }
  }
}
</style>
