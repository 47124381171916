<template>
  <!-- Preloader -->
  <div v-if="loading" class="row mt-5 mb-5">
    <div class="col">
      <div class="preloader-wrapper">
        <div class="preloader-border"></div>
        <div class="preloader-line-mask">
          <div class="preloader-line"></div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="!loading" class="container">
    <div class="row mt-5 mb-md-5">
      <div class="col-md-6 col-xl-4 mb-5 mb-xl-0">
        <div class="num-counter">
          <h2 class="count-value count-run display-4 font-weight-800 text-center mb-4">{{ spaces }}</h2>
          <div class="icon-info-3">
            <div class="icon-title d-flex justify-content-center align-items-end mb-2">
              <img src="assets/images/parking.png" alt="icon" class="mr-2">
              <h4 class="mb-0 font-weight-700">Total Parking Spaces</h4>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-xl-4 mb-5 mb-xl-0">
        <div class="num-counter">
          <h2 class="count-value count-run display-4 font-weight-800 text-center mb-4">{{ garages }}</h2>
          <div class="icon-info-3">
            <div class="icon-title d-flex justify-content-center align-items-end mb-2">
              <img src="assets/images/parking-garage.png" alt="icon" class="mr-2">
              <h4 class="mb-0 font-weight-700">Parking Garages</h4>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-xl-4 mb-5 mb-md-0">
        <div class="num-counter">
          <h2 class="count-value count-run display-4 font-weight-800 text-center mb-4">{{ lots }}</h2>
          <div class="icon-info-3">
            <div class="icon-title d-flex justify-content-center align-items-end mb-2">
              <img src="assets/images/parking-lot.png" alt="icon" class="mr-2">
              <h4 class="mb-0 font-weight-700">Parking Lots</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid px-0">
    <div class="row map-wrapper no-gutters">
      <div :class="{ 'col-xl-8 col-lg-7': showList, 'col-12': !showList, 'd-none d-md-block': showList }"
        class="map-container" id="map">
      </div>
      <div v-if="showList" class="col-xl-4 col-lg-5 px-4 py-2">
        <div class="text-right">
          <button type="button" class="btn btn-round btn-sm btn-icon-only btn-outline-dark" @click="resizeMap(false)"><i
              class="fas fa-times"></i></button>
        </div>
        <ul class="recent-blogs properties">
          <li v-for="item of list" :key="item.properties.propertyId" class="pb-4">
            <div class="media">
              <div class="hover-item hover-uncaption mr-4 rounded shadow">
                <a @click.prevent="viewImage(item.properties.image)">
                  <div class="hover-transition rounded">
                    <div class="hover-down">
                      <i class="fas fa-link fa-2x hover-icon"></i>
                    </div>
                  </div>
                  <figure v-if="item.properties.image">
                    <!-- <img :src="'/assets/images/parks/' + item.properties.image" alt="image"> -->
                    <img :src="item.properties.image" alt="image">
                  </figure>
                </a>
              </div>
              <div class="media-body">
                <h6 class="blog-title font-italic mb-1">
                  <a href="#">{{ item.properties.name }}</a>
                </h6>
                <!-- <p class="mb-0"><b>Property ID: </b>{{ item.properties.propertyId }}</p> -->
                <p class="mb-0"><b>Parking Type: </b>{{ item.properties.type }}</p>
                <p class="mb-0"><b>Parking Space: </b>{{ item.properties.spaces }}</p>
                <p class="mb-0"><b>Location: </b>
                  <a :href="getMapUrl(item.properties)" target="_blank" class="address">
                    <i class="fas fa-location-arrow mx-1 text-primary"></i>
                    {{ item.properties.address }} {{ item.properties.city }}, {{ item.properties.state }} {{
                        item.properties.zip
                    }}
                  </a>
                </p>
                <button type="button" class="btn btn-round btn-sm btn-outline-danger mt-1"
                  @click="viewProperty(item)">View More</button>
              </div>
            </div>
          </li>

        </ul>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="modalImage" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content rounded-xl bg-transparent bg-image" :style="{ 'background-image': 'url(' + url + ')' }">
        <div class="modal-body"></div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="modalProperty" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content rounded-xl bg-transparent bg-image"
        :style="{ 'background-image': 'url(' + property?.properties.image + ')' }">
        <div class="modal-body p-0">

          <button type="button" class="close close-white" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"></span>
          </button>

          <div class="px-4 py-5"
            :style="{ 'background-color': property?.properties.image ? 'transparent' : '#0000009f' }">
            <div class="row">
              <div class="col-12">

                <h2 class="section-title-4 title-light-2 text-center font-weight-700 py-2 mb-1">
                  {{ property?.properties.name }}
                </h2>

                <p class="text-center">
                  <a v-if="property" :href="getMapUrl(property.properties)" target="_blank" class="text-white">
                    <i class="fas fa-location-arrow mx-1 text-danger"></i>
                    {{ property?.properties.address }} {{ property?.properties.city }}, {{ property?.properties.state }}
                    {{ property?.properties.zip }}
                  </a>
                </p>

                <div class="row text-white">
                  <div class="col-md-6 pr-md-0 mb-1">
                    Latitude: {{ property?.geometry.coordinates[1] }}
                  </div>
                  <div class="col-md-6 pl-md-0 mb-1">
                    Longitude: {{ property?.geometry.coordinates[0] }}
                  </div>
                  <div v-if="property?.properties.website" class="col-md-6 pr-md-0 mb-1">
                    Website:
                  </div>
                  <div v-if="property?.properties.timezone" class="col-md-6 pl-md-0 mb-1">
                    Timezone:
                  </div>
                  <div class="col-md-6 pr-md-0 mb-1">
                    Total Spaces: {{ property?.properties.spaces }}
                  </div>
                  <div v-if="property?.properties.operator" class="col-md-6 pl-md-0 mb-1">
                    Operator:
                  </div>
                  <div v-if="property?.properties.tags" class="col-md-12 mb-1">
                    Tags:
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import mapboxgl from "mapbox-gl";

import jsonData from './../../data.json'

const $ = window.$;

export default {
  data() {
    return {
      loading: true,
      spaces: 0,
      garages: 0,
      lots: 0,
      map: null,
      properties: null,
      showList: false,
      list: [],
      url: null,
      property: null
    }
  },
  methods: {
    isScrolledIntoView(element) {
      const docViewTop = $(window).scrollTop(),
        docViewBottom = docViewTop + $(window).height(),
        elementTop = $(element).offset().top,
        elementBottom = elementTop + $(element).height();

      return ((elementBottom > docViewTop) && (elementTop < docViewBottom));
    },
    counterViewport() {
      const self = this;
      $('.num-counter').each(function () {
        if (self.isScrolledIntoView(this) === true) {

          const countRun = $(this).find('.count-run');

          countRun.prop('Counter', 0).animate({
            Counter: countRun.text()
          }, {
            duration: 2000,
            easing: 'swing',
            step: function (now) {
              countRun.text(Math.ceil(now).toLocaleString());
            }
          });
          countRun.removeClass('count-run');
        }
      });
    },
    resizeMap(v) {
      this.showList = v;
      setTimeout(() => {
        this.map.resize();
      }, 0);
    },
    getMapUrl(properties) {
      const { address, city, state, zip, latitude, longitude } = properties;
      if (latitude && longitude) {
        return `http://maps.google.com?q=${latitude},${longitude}`;
      }
      return `http://maps.google.com?q=${address},${city},${state},${zip}`;
    },
    viewImage(url) {
      this.url = url;
      setTimeout(() => {
        $('#modalImage').modal('show');
      }, 300);
    },
    viewProperty(property) {
      this.property = property;
      setTimeout(() => {
        $('#modalProperty').modal('show');
      }, 300);
    },
    dataHandler(data) {
      const tmpFeatures = [];
      let tmpSpaces = 0, tmpGarages = 0, tmpLots = 0;
      data.forEach(el => {
        tmpFeatures.push({
          type: 'Feature',
          properties: {
            image: el.imageUrl,
            name: el.name,
            address: el.address,
            city: el.city,
            state: el.state,
            zip: el.zip,
            type: el.propertyType,
            spaces: el.totalSpaces,
            latitude: el.coordinate.coordinates[1],
            longitude: el.coordinate.coordinates[0]
          },
          geometry: el.coordinate
        });
        tmpSpaces += el.totalSpaces;
        if (el.propertyType === 'parking.garage') {
          tmpGarages++;
        }
        if (el.propertyType === 'parking.lot') {
          tmpLots++;
        }
      });

      this.spaces = tmpSpaces;
      this.garages = tmpGarages;
      this.lots = tmpLots;

      this.properties = {
        type: 'FeatureCollection',
        features: tmpFeatures
      }
    }
  },
  mounted() {
    mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_ACCESS_TOKEN;

    this.map = new mapboxgl.Map({
      container: "map",
      style: process.env.VUE_APP_MAPBOX_STYLE,
      center: [-95.7129, 37.0902],
      zoom: 4,
    });
    this.map.scrollZoom.disable();
    this.map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');

    this.map.on('load', () => {
      this.map.addSource('properties', {
        type: 'geojson',
        data: this.properties,
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 50
      });

      this.map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'properties',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#e53935',
            100,
            '#f1f075',
            750,
            '#f28cb1'
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40
          ]
        }
      });

      this.map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'properties',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        },
        paint: {
          'text-color': '#ffffff',
        }
      });

      this.map.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'properties',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': '#e53935',
          'circle-radius': 12,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff'
        }
      });

      this.map.addLayer({
        id: 'unclustered-count',
        type: 'symbol',
        source: 'properties',
        filter: ['!', ['has', 'point_count']],
        layout: {
          'text-field': '1',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        },
        paint: {
          'text-color': '#ffffff',
        }
      });

      this.map.on('click', 'clusters', (e) => {
        this.resizeMap(true)
        const features = this.map.queryRenderedFeatures(e.point, {
          layers: ['clusters']
        });
        const clusterId = features[0].properties.cluster_id;
        const pointCount = features[0].properties.point_count;
        const clusterSource = this.map.getSource('properties');

        clusterSource.getClusterLeaves(clusterId, pointCount, 0, (error, features) => {
          if (!error) {
            this.list = features;
          } else {
            this.list = [];
          }
        });
      });

      this.map.on('click', 'unclustered-point', (e) => {
        this.resizeMap(true)
        const features = this.map.queryRenderedFeatures(e.point, {
          layers: ['unclustered-point']
        });
        this.list = [features[0]];
      });

      this.map.on('mouseenter', 'clusters', () => {
        this.map.getCanvas().style.cursor = 'pointer';
      });
      this.map.on('mouseleave', 'clusters', () => {
        this.map.getCanvas().style.cursor = '';
      });
      this.map.on('mouseenter', 'unclustered-point', () => {
        this.map.getCanvas().style.cursor = 'pointer';
      });
      this.map.on('mouseleave', 'unclustered-point', () => {
        this.map.getCanvas().style.cursor = '';
      });
    });
  },
  created() {
    this.loading = true;

    const url = 'property/website?website=mobileit.com';
    axios.get(url).then(res => {
      console.log('property website: ', res.data);
      this.dataHandler(res.data);

      this.loading = false;

      setTimeout(() => {
        this.counterViewport();
      }, 0);
    }).catch(err => {
      console.log('proerty website error: ', err);
      this.dataHandler(jsonData.properties);

      this.loading = false;
    })
  }
}
</script>

<style lang="scss" scoped>
.icon-title {
  img {
    width: 30px;
    height: 30px;
  }
}

.map-wrapper {
  height: calc(100vh - 120px);

  @media (max-width: 768px) {
    height: auto;
  }

  .map-container {
    @media (max-width: 768px) {
      height: calc(100vh - 200px);
    }
  }

  .properties {
    max-height: calc(100vh - 206px);
    overflow: auto;
    // -ms-overflow-style: none;
    // scrollbar-width: none;
    // &::-webkit-scrollbar {
    //   display: none;
    // }

    @media (max-width: 768px) {
      max-height: auto;
    }

    img {
      width: 150px;
      height: 150px;

      @media (max-width: 768px) {
        width: 100px;
        height: 100px;

      }
    }

    .address {
      color: #717171;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

#modalImage {
  .modal-body {
    min-width: 50vw;
    min-height: 60vh;
  }
}
</style>
