<template>
  <!-- Page title -->
  <div class="d-flex flex-column w-100">
    <div
      class="page-title d-flex align-items-center bg-image py-5"
      style="background-image: url('assets/images/news-bg.jpg')"
    >
      <div class="container page-title-container">
        <div class="row">
          <div class="col text-center">
            <h1 class="display-3 font-weight-800 text-white mb-4 mb-md-0">
              IN THE NEWS
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Breadcrumb -->
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="bg-danger py-4 mt-up50 rounded-xl shadow-lg">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-light-2 px-md-4 mb-0">
              <li class="breadcrumb-item text-uppercase">
                <router-link to="/">Home</router-link>
              </li>
              <li
                class="breadcrumb-item text-uppercase active"
                aria-current="page"
              >
                NEWS
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>

  <!-- Content -->
  <div class="main-content">
    <div class="section">
      <div class="container">
        <!-- No data -->
        <div v-if="articles.length === 0" class="row">
          <div class="col text-center">
            <p class="h3">No available data</p>
          </div>
        </div>

        <!-- Content section -->
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <ul class="custom-list fa-ul mb-0">
              <li v-for="item in articles" :key="item.url" class="mb-1">
                <span class="fa-li"
                  ><i class="fas fa-link text-danger"></i
                ></span>
                <a :href="item.url" target="_blank">{{ item.name }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["articles"]),
  },
};
</script>

<style></style>
