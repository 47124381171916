import { createStore } from 'vuex'

const store = createStore({
  state () {
    return {
      websiteId: null,
      teams: [],
      boards: [],
      articles: [],
      showBlog: false,
      socials: [],
      faqs: [],
      posts: []
    }
  },
  mutations: {
    setWebsiteId(state, id) {
      state.websiteId = id;
    },
    setTeams(state, teams) {
      state.teams = teams;
    },
    setBoards(state, boards) {
      state.boards = boards;
    },
    setArticles(state, articles) {
      state.articles = articles;
    },
    setShowBlog(state, value) {
      state.showBlog = value;
    },
    setSocials(state, socials) {
      state.socials = socials;
    },
    setFaqs(state, faqs) {
      state.faqs = faqs;
    },
    setPosts(state, posts) {
      state.posts = posts;
    }
  }
})

export default store;