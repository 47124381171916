<template>
  <!-- Page title -->
  <div class="d-flex flex-column w-100">
    <div class="page-title d-flex align-items-center bg-image py-5"
      style="background-image: url('assets/images/team-bg.jpg')">
      <div class="container page-title-container">
        <div class="row">
          <div class="col text-center">

            <h1 class="display-3 font-weight-800 text-white mb-4 mb-md-0">
              Team
            </h1>

          </div>
        </div>
      </div>
    </div>
  </div>
  
  <!-- Breadcrumb -->
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="bg-danger py-4 mt-up50 rounded-xl shadow-lg">

          <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-light-2 px-md-4 mb-0">
              <li class="breadcrumb-item text-uppercase"><router-link to="/">Home</router-link></li>
              <li class="breadcrumb-item text-uppercase active" aria-current="page">Team</li>
            </ol>
          </nav>

        </div>
      </div>
    </div>
  </div>

  <!-- Content -->
  <div class="main-content">

    <div class="section">
      <div class="container">

        <!-- No data -->
        <div v-if="filteredData.length===0" class="row">
          <div class="col text-center">
            <p class="h3">No available data</p>
          </div>
        </div>

        <!-- Tag section -->
        <div v-if="filteredData.length>0" class="row align-items-center pb-4 pb-xl-5">

          <div class="col-xl-12">
            <div class="grid-item-filter">
              <ul class="nav nav-pills justify-content-center justify-content-xl-end">
                <li class="nav-item my-2">
                  <a :class="{'active': currentTag==='all'}" class="nav-link" href="#" @click.prevent="currentTag='all'">All</a>
                </li>
                <li v-for="t in tags" :key="t.id" class="nav-item my-2">
                  <a :class="{'active': currentTag===t}" class="nav-link" href="#" @click.prevent="currentTag=t">{{ t.name }}</a>
                </li>
              </ul>
            </div>
          </div>

        </div>

        <!-- Content section -->
        <div v-if="filteredData.length>0" class="row">
          <div class="col">
            <div class="row grid-item-layout">

              <!-- Grid item -->
              <div v-for="item in filteredData" :key="item.id" class="col-md-4 col-lg-3 grid-item cat-all cat-mockup cat-media">
                <div class="card card-blog-entry border-0 plain-card mb-2">
                  <div class="card-blog-img hover-item hover-scale hover-uncaption rounded">
                    <a>
                      <div class="hover-transition">
                        <div v-for="s in item.socialLinks" :key="s.linkType" class="hover-down">
                          <i :class="getIcon(s.linkType)" class="fa-2x hover-icon" @click="openSocialLink(s.link)"></i>
                        </div>
                      </div>
                      <figure class="text-center">
                        <img class="img-fluid" :src="item.image" alt="image">
                      </figure>
                    </a>
                  </div>
                  <div class="card-body">
                    <h5 class="font-weight-700 mt-2">
                      <a class="text-dark-gray">{{ item.name }}</a>
                    </h5>
                    <h6 class="text-primary">{{ item.title }} </h6>
                    <ul class="meta-entry mb-0">
                      <li v-for="t in item.tags" :key="t.id" class="meta-entry-item">
                        <a href="#" @click.prevent="currentTag=t">{{ t.name }}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      tags: [],
      currentTag: 'all'
    }
  },
  computed: {
    ...mapState(['teams']),
    filteredData() {
      if (this.currentTag === 'all') {
        return this.teams.map(x => {
          const image = x.image ? x.image : 'assets/images/' + x.gender.toLowerCase() + '-avatar.png';

          return {
            ...x,
            image
          }
        }).sort((a, b) => !a.order || !b.order ? 1 : (a.order > b.order) ? 1 : -1);
      } else {
        const results = [];
        this.teams.forEach(e => {
          const index = e.tags.findIndex(x => x.id === this.currentTag.id);
          if (index > -1) {
            const image = e.image ? e.image : 'assets/images/' + e.gender.toLowerCase() + '-avatar.png';

            results.push({
              ...e,
              image
            });
          }
        });

        return results.sort((a, b) => !a.order || !b.order ? 1 : (a.order > b.order) ? 1 : -1);
      }
    }
  },
  methods: {
    setTags() {
      if (this.teams.length === 0) {
        return;
      }

      this.teams.forEach(e => {
        e.tags.forEach(t => {
          const index = this.tags.findIndex(x => x.id === t.id);
          if (index === -1) {
            this.tags.push(t);
          }
        });
      });

      this.tags.sort((a, b) => { 
        return a.order - b.order || a.name.localeCompare(b.name);
      });
    },
    getIcon(type) {
      switch (type) {
        case 'LinkedIn':
          return 'fab fa-linkedin-in';
        case 'Facebook':
          return 'fab fa-facebook-square';
        case 'Google':
          return 'fab fa-google';
        case 'Twitter':
          return 'fab fa-twitter-square';
      
        default:
          return 'fab fa-question-circle';
      }
    },
    openSocialLink(link) {
      window.open(link, '_blank').focus();
    }
  },
  created() {
    this.setTags();
  },
  watch: {
    teams() {
      this.setTags();
    }
  }
}
</script>

<style lang="scss" scoped>
  .img-fluid {
    width: 256px;
    height: 256px;
    object-fit: cover;
  }
</style>