<template>
  <!-- Page title -->
  <div class="d-flex flex-column w-100">
    <div class="page-title d-flex align-items-center bg-image py-5"
      style="background-image: url('assets/images/page-title-bg-02.jpg')">
      <div class="container page-title-container">
        <div class="row">
          <div class="col text-center">

            <h1 class="display-3 font-weight-800 text-white mb-4 mb-md-0">
              Investor Highlights
            </h1>

          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Breadcrumb -->
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="bg-danger py-4 mt-up50 rounded-xl shadow-lg">

          <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-light-2 px-md-4 mb-0">
              <li class="breadcrumb-item text-uppercase"><router-link to="/">Home</router-link></li>
              <li class="breadcrumb-item text-uppercase text-nowrap active" aria-current="page">Investor Highlights</li>
            </ol>
          </nav>

        </div>
      </div>
    </div>
  </div>

  <!-- Content -->
  <div class="main-content pb-0">

    <div class="section w-100">
      <div class="container-fluid px-0">

        <!-- Row item -->
        <div class="row no-gutters">

          <div
            class="col-lg-6 arrow-overflow-container d-flex align-items-center bg-indigo text-white-75 order-2 order-lg-1">

            <svg class="arrow-overflow-right d-none d-lg-block" fill="#1565c0" xmlns="http://www.w3.org/2000/svg"
              viewBox="12.6 3.8 18 34" width="18px" height="34px">
              <path d="M12.6,3.8v34l18-17L12.6,3.8z"></path>
            </svg>

            <svg class="arrow-overflow-up d-lg-none" fill="#1565c0" xmlns="http://www.w3.org/2000/svg"
              viewBox="3 10.2 34 18" width="34px" height="18px">
              <path d="M20,10.2l-17,18h34L20,10.2z"></path>
            </svg>

            <div class="py-5">
              <div class="row no-gutters">
                <div class="col-md-10 offset-md-1 px-4 py-5">

                  <h2 class="h1 section-title-4 title-light-1 text-left font-weight-800 mb-4">
                    Investment Objectives
                    <div class="title-divider-round"></div>
                  </h2>

                  <div class="lead-sm font-weight-500 pt-2 pb-4 mb-5">
                    <ul>
                      <li>Preserve, protect and return your capital contribution;</li>
                      <li>provide periodic distributions once we have acquired a substantial portfolio or investments; and</li>
                      <li>Realize Growth in the value of our investments.</li>
                    </ul>
                  </div>

                </div>
              </div>
            </div>

          </div>

          <div class="col-lg-6 order-1 order-lg-2">
            <div class="h-100 hover-item hover-scale hover-uncaption">
              <a href="#">

                <div class="hover-transition">
                  <div class="hover-down">
                    <i class="far fa-link fa-2x hover-icon"></i>
                  </div>
                </div>

                <figure class="h-100 bg-image" style="background-image: url('assets/images/stock_couple_01.gif')">
                  <img class="w-100 h-100" src="assets/images/stock_couple_01.gif" alt="image cover">
                </figure>

              </a>
            </div>
          </div>

        </div>

      </div>
    </div>

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>