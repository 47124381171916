<template>
  <footer>
    <div class="bg-viridian-500 text-white-50">
      <div class="py-5">
        <div class="container">
          <div class="row py-5">
            <div class="col-lg-5 mb-5 mb-lg-0">
              <div class="widget">
                <img
                  class="img-fluid d-block mb-4 logo"
                  src="assets/images/logo-white.png"
                  alt="Mobile Infrastructure Logo"
                />

                <p>
                  The Parking REIT, Inc. has changed its name to Mobile
                  Infrastructure Corporation. Any reference to The Parking REIT
                  is now known as Mobile Infrastructure Corporation
                </p>
              </div>
            </div>

            <div class="col-lg-4 offset-lg-1 mb-5 mb-lg-0">
              <div class="widget">
                <h6
                  class="widget-title text-white text-uppercase font-weight-700"
                >
                  Get in touch
                </h6>

                <div class="row mb-3">
                  <div class="col-2 col-lg-3 col-xl-2">
                    <span class="text-white font-weight-500">Address</span>
                  </div>

                  <div class="col-10 col-lg-9 col-xl-10">
                    <div class="pl-2">
                      30 West 4th Street<br />Cincinnati, OH 45202
                    </div>
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-2 col-lg-3 col-xl-2">
                    <span class="text-white font-weight-500">Phone</span>
                  </div>

                  <div class="col-10 col-lg-9 col-xl-10">
                    <div class="pl-2">+1 212 509 4000</div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-2 col-lg-3 col-xl-2">
                    <span class="text-white font-weight-500">Email</span>
                  </div>

                  <div class="col-10 col-lg-9 col-xl-10">
                    <div class="pl-2">IR@MobileIT.com</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-bottom py-4">
        <div class="container footer-bottom-container">
          <div class="row py-3">
            <div class="col-lg-6 text-center text-lg-left mb-2 mb-lg-0">
              <small>Copyright @ mobileit.com. All Rights Reserved.</small>
            </div>

            <div class="col-lg-6 text-center text-lg-right"></div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "m-plainfooter",
  computed: {
    ...mapState([
      "socials",
      // 'posts'
    ]),
  },
  methods: {
    getIcon(type) {
      switch (type) {
        case "LinkedIn":
          return "fab fa-linkedin-in";
        case "Facebook":
          return "fab fa-facebook-square";
        case "Google":
          return "fab fa-google";
        case "Twitter":
          return "fab fa-twitter-square";

        default:
          return "fab fa-question-circle";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
