<template>
  <!-- Page title -->
  <div class="d-flex flex-column w-100">
    <div class="page-title d-flex align-items-center bg-image py-5"
      style="background-image: url('assets/images/about-us-bg.jpg')">
      <div class="container page-title-container">
        <div class="row">
          <div class="col text-center">

            <h1 class="display-3 font-weight-800 text-white mb-4 mb-md-0">
              About Us
            </h1>

          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="bg-danger py-4 mt-up50 rounded-xl shadow-lg">

          <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-light-2 px-md-4 mb-0">
              <li class="breadcrumb-item text-uppercase"><router-link to="/">Home</router-link></li>
              <li class="breadcrumb-item text-uppercase active" aria-current="page">About Us</li>
            </ol>
          </nav>

        </div>
      </div>
    </div>
  </div>

  <!-- Content -->
  <div class="main-content pb-0">

    <div class="section">
      <div class="container-fluid p-0">
        <div class="row no-gutters">

          <div class="col-lg-4 bg-image p-5 add-animate" data-animated="fadeInLeft"
            style="background-image: url('assets/images/about-us-vision-bg-01.jpg')">
            <div class="row">
              <div class="col-xl-10 offset-xl-1">
                <div class="text-white-75 my-2">
                  <h3 class="h1 text-white font-weight-800">01</h3>
                  <h4 class="text-white font-weight-800 text-uppercase mb-3">Vision</h4>
                  <p>Transport Beyond</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 bg-image p-5 add-animate" data-animated="fadeInUp"
            style="background-image: url('assets/images/about-us-vision-bg-02.jpg')">
            <div class="row">
              <div class="col-xl-10 offset-xl-1">
                <div class="text-white-75 my-2">
                  <h3 class="h1 text-white font-weight-800">02</h3>
                  <h4 class="text-white font-weight-800 text-uppercase mb-3">Mission</h4>
                  <p>Ecosystems Created & Sustained Through Movement</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 bg-image p-5 add-animate" data-animated="fadeInRight"
            style="background-image: url('assets/images/about-us-vision-bg-03.jpg')">
            <div class="row">
              <div class="col-xl-10 offset-xl-1">
                <div class="my-2">
                  <h3 class="h1 text-indigo font-weight-800">03</h3>
                  <h4 class="font-weight-800 text-uppercase mb-3">Values</h4>
                  <ul>
                    <li>Aspirational</li>
                    <li>Fearless Pursuit of Excellence</li>
                    <li>Iterative Communication</li>
                    <li>Transparency</li>
                    <li>Trust</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>