<template>
  <header class="navik-header header-shadow">
    <div class="container">
      <div class="navik-header-container">
        <div
          class="logo"
          data-mobile-logo="assets/images/logo.png"
          data-sticky-logo="assets/images/logo.png"
        >
          <router-link to="/"
            ><img src="assets/images/logo.png" alt="Mobile Infrastructure Logo"
          /></router-link>
        </div>

        <div class="burger-menu">
          <div class="line-menu line-half first-line"></div>
          <div class="line-menu"></div>
          <div class="line-menu line-half last-line"></div>
        </div>

        <nav class="navik-menu menu-hover-3 menu-caret submenu-top-border">
          <ul>
            <li :class="{ 'current-menu': path === '/' }">
              <router-link to="/" @click="hideMobileMenu">Home</router-link>
            </li>
            <!-- <li :class="{'current-menu': path==='/why-parking'}"><router-link to="/why-parking" @click="hideMobileMenu">Why Parking</router-link></li> -->
            <!-- <li :class="{'current-menu': (path==='/mobility' || path==='/parking' || path==='/ev' || path==='/scooters')}" class="dropdown_menu"><router-link to="/mobility" @click="hideMobileMenu">Mobility<span></span></router-link>
              <ul>                
                <li><router-link to="/mobility" @click="hideMobileMenu">Mobility</router-link></li>
                <li><router-link to="/parking" @click="hideMobileMenu">Parking</router-link></li>
                <li><router-link to="/ev" @click="hideMobileMenu">EV</router-link></li>
                <li><router-link to="/scooters" @click="hideMobileMenu">Scooters</router-link></li>
              </ul>
              <span class="dropdown-plus"></span>
            </li> -->
            <li
              :class="{
                'current-menu':
                  path === '/parking-locations' || path === '/portfolio',
              }"
              class="dropdown_menu"
            >
              <router-link to="/parking-locations" @click="hideMobileMenu"
                >Parking Locations<span></span
              ></router-link>
              <ul>
                <li>
                  <router-link to="/parking-locations" @click="hideMobileMenu"
                    >Parking Locations</router-link
                  >
                </li>
                <li>
                  <router-link to="/portfolio" @click="hideMobileMenu"
                    >Portfolio</router-link
                  >
                </li>
              </ul>
              <span class="dropdown-plus"></span>
            </li>
            <li
              :class="{
                'current-menu':
                  path === '/about-us' ||
                  path === '/team' ||
                  path === '/board' ||
                  path === '/news',
              }"
              class="dropdown_menu"
            >
              <router-link to="/about-us" @click="hideMobileMenu"
                >About Us<span></span
              ></router-link>
              <ul>
                <li>
                  <router-link to="/about-us" @click="hideMobileMenu"
                    >About Us</router-link
                  >
                </li>
                <li>
                  <router-link to="/team" @click="hideMobileMenu"
                    >Team</router-link
                  >
                </li>
                <li>
                  <router-link to="/board" @click="hideMobileMenu"
                    >Board</router-link
                  >
                </li>
              </ul>
              <span class="dropdown-plus"></span>
            </li>
            <li v-if="showBlog" :class="{ 'current-menu': path === '/blog' }">
              <router-link to="/blog" @click="hideMobileMenu">Blog</router-link>
            </li>
            <!-- <li>
              <a href="https://ir.mobileit.com">Investor Relations</a>
            </li> -->
            <li
              :class="{
                'current-menu':
                  path === '/investor-relations' ||
                  path === '/investor-highlights' ||
                  path === '/corporate-governance' ||
                  path === '/investor-paperwork' ||
                  path === '/faq',
              }"
              class="dropdown_menu"
            >
              <a href="https://ir.mobileit.com">Investor Relations</a>
              <ul>
                <ul>
                  <li>
                    <a href="https://ir.mobileit.com/news-events"
                      >News Events</a
                    >
                  </li>
                  <li>
                    <a href="https://ir.mobileit.com/company-information"
                      >Company Info</a
                    >
                  </li>
                  <li>
                    <a href="https://ir.mobileit.com/financial-information"
                      >Financial Info</a
                    >
                  </li>
                  <li>
                    <a href="https://ir.mobileit.com/stock-info">Stock Info</a>
                  </li>
                  <li>
                    <a href="https://ir.mobileit.com/sec-filings"
                      >SEC Filings</a
                    >
                  </li>
                  <li>
                    <a href="https://ir.mobileit.com/corporate-governance"
                      >Governance</a
                    >
                  </li>
                </ul>
              </ul>
              <span class="dropdown-plus"></span>
            </li>
            <li :class="{ 'current-menu': path === '/contact-us' }">
              <router-link to="/contact-us" @click="hideMobileMenu"
                >Contact us</router-link
              >
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div class="header-shadow-wrapper"></div>
  </header>
</template>

<script>
import { mapState } from "vuex";
const $ = window.$;
export default {
  name: "m-header",
  data() {
    return {
      path: "/",
    };
  },
  computed: {
    ...mapState(["showBlog"]),
  },
  methods: {
    hideMobileMenu() {
      const menu = this.$el.querySelector(".burger-menu");
      if (menu.classList.contains("menu-open")) {
        const ul = this.$el.querySelector("ul");
        ul.style.display = "none";
        menu.classList.remove("menu-open");
      }
    },
    stickyNav() {
      const scrollTop = $(window).scrollTop(),
        noSticky = $(".no-sticky"),
        viewportSm = $(".viewport-sm"),
        viewportLg = $(".viewport-lg"),
        viewportLgBody = viewportLg.parent("#app").parent("body"),
        navikHeader = $(".navik-header"),
        navikHeaderHeight = navikHeader.height();

      if (scrollTop > navikHeaderHeight) {
        navikHeader.addClass("sticky");
        viewportLgBody.css("margin-top", navikHeaderHeight);
        viewportLg.css("margin-top", -navikHeaderHeight);
      } else {
        navikHeader.removeClass("sticky");
        viewportLgBody.add(viewportLg).css("margin-top", "0");
      }

      noSticky.removeClass("sticky");
      viewportSm.removeClass("sticky");
    },
  },
  watch: {
    $route(to) {
      this.path = to.path;
    },
  },
  mounted() {
    if (!$) {
      return;
    }

    /* ========== Dropdown Menu Toggle ========== */
    $(".navik-header")
      .find(".burger-menu")
      .on("click", function () {
        $(this).toggleClass("menu-open");
        $(".navik-menu > ul").slideToggle(300);
      });

    $(".dropdown-plus").on("click", function () {
      $(this).prev("ul").slideToggle(300);
      $(this).toggleClass("dropdown-open");
    });

    /* ========== Window resize ========== */
    $(window)
      .on("resize", () => {
        if ($(window).width() < 1280) {
          $(".navik-header").removeClass("viewport-lg");
          $(".navik-header").addClass("viewport-sm");
        } else {
          $(".navik-header").removeClass("viewport-sm");
          $(".navik-header").addClass("viewport-lg");
        }

        this.stickyNav();
      })
      .resize();

    /* ========== Sticky on scroll ========== */
    $(window)
      .on("scroll", () => {
        this.stickyNav();
      })
      .scroll();
  },
};
</script>
