<template>
  <!-- Page title -->
  <div class="d-flex flex-column w-100">
    <div class="page-title d-flex align-items-center bg-image py-5"
      style="background-image: url('assets/images/portfolio-bg.jpg')">
      <div class="container page-title-container">
        <div class="row">
          <div class="col text-center">

            <h1 class="display-3 font-weight-800 text-white mb-4 mb-md-0">
              Portfolio
            </h1>

          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col">
        <div class="bg-danger py-4 mt-up50 rounded-xl shadow-lg">

          <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-light-2 px-md-4 mb-0">
              <li class="breadcrumb-item text-uppercase"><router-link to="/">Home</router-link></li>
              <li class="breadcrumb-item text-uppercase active" aria-current="page">Portfolio</li>
            </ol>
          </nav>

        </div>
      </div>
    </div>
  </div>

  <!-- Content -->
  <div class="main-content">

    <!-- Default -->
    <div class="section">
      <div class="container">
        
        <!-- Preloader -->
        <div v-if="loading" class="row">
          <div class="col">
            <div class="preloader-wrapper">
              <div class="preloader-border"></div>
              <div class="preloader-line-mask">
                <div class="preloader-line"></div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="row pb-5 mb-5">
          <div class="col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
            <div class="text-center">

              <h2 class="h1 section-title-3 font-weight-800">Parking</h2>

            </div>
          </div>
        </div> -->

        <div v-if="!loading" class="row justify-content-center">

          <div class="col-md-6 col-xl-4 mb-5">
            <div class="num-counter">
              <h2 class="count-value count-run display-4 font-weight-800 text-center mb-4">22</h2>
              <div class="icon-info-3">
                <div class="icon-title d-flex justify-content-center align-items-end mb-2">
                  <i class="fas fa-city fa-2x text-primary mr-2"></i>
                  <h4 class="mb-0 font-weight-700">Markets </h4>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-xl-4 mb-5">
            <div class="num-counter">
              <h2 class="count-value count-run display-4 font-weight-800 text-center mb-4">{{ garages }}</h2>
              <div class="icon-info-3">
                <div class="icon-title d-flex justify-content-center align-items-end mb-2">
                  <i class="fas fa-warehouse fa-2x text-primary mr-2"></i>
                  <h4 class="mb-0 font-weight-700">Garages</h4>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-xl-4 mb-5">
            <div class="num-counter">
              <h2 class="count-value count-run display-4 font-weight-800 text-center mb-4">{{ lots }}</h2>
              <div class="icon-info-3">
                <div class="icon-title d-flex justify-content-center align-items-end mb-2">
                <i class="fas fa-car fa-2x text-primary"></i>
                <i class="fas fa-car fa-2x text-primary mr-2"></i>
                  <h4 class="mb-0 font-weight-700">Lots</h4>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="col-md-6 col-xl-4 mb-5">
            <div class="num-counter">
              <h2 class="count-value count-run display-4 font-weight-800 text-center mb-4">{{ lanes }}</h2>
              <div class="icon-info-3">
                <div class="icon-title d-flex justify-content-center align-items-end mb-2">
                  <i class="fas fa-road fa-2x text-primary mr-2"></i>
                  <h4 class="mb-0 font-weight-700">Lanes</h4>
                </div>
              </div>
            </div>
          </div> -->

          <div class="col-md-6 col-xl-4 mb-5">
            <div class="num-counter">
              <h2 class="count-value count-run display-4 font-weight-800 text-center mb-4">{{ spaces }}</h2>
              <div class="icon-info-3">
                <div class="icon-title d-flex justify-content-center align-items-end mb-2">
                  <i class="fas fa-car fa-2x text-primary mr-2"></i>
                  <h4 class="mb-0 font-weight-700">Spaces</h4>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="col-md-6 col-xl-4">
            <div class="num-counter">
              <h2 class="count-value count-run display-4 font-weight-800 text-center mb-4">{{ asphaltSqFt }}</h2>
              <div class="icon-info-3">
                <div class="icon-title d-flex justify-content-center align-items-end mb-2">
                  <i class="fas fa-square-full fa-2x text-primary mr-2"></i>
                  <h4 class="mb-0 font-weight-700">Sq. ft Asphalt</h4>
                </div>
              </div>
            </div>
          </div> -->

          <div class="col-md-6 col-xl-4 mb-5">
            <div class="num-counter">
              <h2 class="count-value count-run display-4 font-weight-800 text-center mb-4">{{ concreteSqFt }}</h2>
              <div class="icon-info-3">
                <div class="icon-title d-flex justify-content-center align-items-end mb-2">
                  <i class="fas fa-layer-group fa-2x text-primary mr-2"></i>
                  <h4 class="mb-0 font-weight-700">Total Sq Ft</h4>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="col-md-6 col-xl-4">
            <div class="num-counter">
              <h2 class="count-value count-run display-4 font-weight-800 text-center mb-4">{{ evChargers }}</h2>
              <div class="icon-info-3">
                <div class="icon-title d-flex justify-content-center align-items-end mb-2">
                  <i class="fas fa-charging-station fa-2x text-primary mr-2"></i>
                  <h4 class="mb-0 font-weight-700">EV Chargers</h4>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-xl-4">
            <div class="num-counter">
              <h2 class="count-value count-run display-4 font-weight-800 text-center mb-4">{{ scooters }}</h2>
              <div class="icon-info-3">
                <div class="icon-title d-flex justify-content-center align-items-end mb-2">
                  <i class="fas fa-skating fa-2x text-primary mr-2"></i>
                  <h4 class="mb-0 font-weight-700">Scooters</h4>
                </div>
              </div>
            </div>
          </div> -->

        </div>

      </div>
    </div>

    <!-- Number counter for light background -->
    <!-- <div class="section bg-light py-5">
      <div class="container">

        <div class="row pt-4 pb-5 my-5">
          <div class="col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
            <div class="text-center">

              <h2 class="h1 section-title-3 font-weight-800">Mobility</h2>

            </div>
          </div>
        </div>

        <div class="row mb-5">

          <div class="col-md-6 col-xl-3 mb-5 mb-xl-0">
            <div class="num-counter">
              <h2 class="count-value count-run display-4 font-weight-800 text-center mb-4">950</h2>
              <div class="icon-info-3">
                <div class="icon-title d-flex justify-content-center align-items-end mb-2">
                  <img src="assets/svg/counter-icon-16.svg" alt="icon" class="mr-2" style="width: 34px; height: 34px">
                  <h4 class="mb-0 font-weight-700">Level 1 EV</h4>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-xl-3 mb-5 mb-xl-0">
            <div class="num-counter">
              <h2 class="count-value count-run display-4 font-weight-800 text-center mb-4">427</h2>
              <div class="icon-info-3">
                <div class="icon-title d-flex justify-content-center align-items-end mb-2">
                  <img src="assets/svg/counter-icon-17.svg" alt="icon" class="mr-2" style="width: 34px; height: 34px">
                  <h4 class="mb-0 font-weight-700">Level 2 EV</h4>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-xl-3 mb-5 mb-md-0">
            <div class="num-counter">
              <h2 class="count-value count-run display-4 font-weight-800 text-center mb-4">850</h2>
              <div class="icon-info-3">
                <div class="icon-title d-flex justify-content-center align-items-end mb-2">
                  <img src="assets/svg/counter-icon-18.svg" alt="icon" class="mr-2" style="width: 34px; height: 34px">
                  <h4 class="mb-0 font-weight-700">Scooters</h4>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-xl-3">
            <div class="num-counter">
              <h2 class="count-value count-run display-4 font-weight-800 text-center mb-4">245</h2>
              <div class="icon-info-3">
                <div class="icon-title d-flex justify-content-center align-items-end mb-2">
                  <img src="assets/svg/counter-icon-19.svg" alt="icon" class="mr-2" style="width: 34px; height: 34px">
                  <h4 class="mb-0 font-weight-700">Bikes</h4>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div> -->

  </div>
</template>

<script>
import axios from 'axios';

import jsonData from './../../data.json'

const $ = window.$;

export default {
  data() {
    return {
      loading: true,
      cities: 0,
      garages: 0,
      lots: 0,
      lanes: 0,
      spaces: 0,
      asphaltSqFt: 0,
      concreteSqFt: 0,
      evChargers: 0,
      scooters: 0,
    }
  },
  methods: {
    isScrolledIntoView(element) {
      const docViewTop = $(window).scrollTop(),
          docViewBottom = docViewTop + $(window).height(),
          elementTop = $(element).offset().top,
          elementBottom = elementTop + $(element).height();

      return ((elementBottom > docViewTop) && (elementTop < docViewBottom));
    },
    counterViewport() {
      const self = this;
      $('.num-counter').each(function () {
        if (self.isScrolledIntoView(this) === true) {

          const countRun = $(this).find('.count-run');

          countRun.prop('Counter', 0).animate({
            Counter: countRun.text()
          }, {
            duration: 2000,
            easing: 'swing',
            step: function (now) {
              countRun.text(Math.ceil(now).toLocaleString());
            }
          });
          countRun.removeClass('count-run');
        }
      });
    },
    dataHandler(data) {
      const tmpCities = [];
      let tmpGarages = 0, tmpLots = 0, tmpLanes = 0, tmpSpaces = 0, tmpAsphaltSqFt = 0, tmpConcreteSqFt = 0, tmpEvChargers = 0, tmpScooters = 0;
      data.forEach(el => {
        if (!tmpCities.includes(el.city)) {
          console.log('city: ', el.city)
          tmpCities.push(el.city);
        }
        if (el.propertyType === 'parking.garage') {
          tmpGarages++;
        }
        if (el.propertyType === 'parking.lot') {
          tmpLots++;
        }
        tmpLanes += el.lanes;
        tmpSpaces += el.totalSpaces;
        tmpAsphaltSqFt += el.asphaltSqFt;
        tmpConcreteSqFt += el.concreteSqFt;
        el.evChargers.forEach(c => {
          tmpEvChargers += c.count;
        });
        el.scooters.forEach(c => {
          tmpScooters += c.count;
        });
      });
      this.cities = tmpCities.length;
      this.garages = tmpGarages;
      this.lots = tmpLots;
      this.lanes = tmpLanes;
      this.spaces = tmpSpaces;
      this.asphaltSqFt = tmpAsphaltSqFt;
      this.concreteSqFt = tmpConcreteSqFt;
      this.evChargers = tmpEvChargers;
      this.scooters = tmpScooters;
    }
  },
  created() {
    this.loading = true;

    const url = 'property/website?website=mobileit.com';
    axios.get(url).then(res => {
      console.log('property website: ', res.data);
      this.dataHandler(res.data);

      this.loading = false;
      setTimeout(() => {
        this.counterViewport();        
      }, 0);
    }).catch(err => {
      console.log('proerty website error: ', err);
      this.dataHandler(jsonData.properties);

      this.loading = false;
    })
  }
}
</script>

<style>

</style>