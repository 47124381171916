<template>
  <!-- Page title -->
  <div class="w-100">
    <div class="d-flex flex-column w-100">
      <div
        class="page-title d-flex align-items-center bg-image py-5"
        style="background-image: url('assets/images/faq-page-title.jpg')"
      >
        <div class="container page-title-container">
          <div class="row py-5">
            <div class="col text-center">

              <h1 class="display-4 font-weight-800 text-white mb-4">
                How Can We Help?
              </h1>              

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Content -->
  <div class="main-content py-0">
    <!-- Accordion answers section -->
    <div class="section py-5">
      <div class="container">
        <div class="row my-5">
          <div class="col-lg-8 offset-lg-2">
            <div class="text-center">
              <div style="height: 3px"></div>

              <h2 class="h1 section-title-4 font-weight-800">
                Frequently Asked Questions
                <div class="title-divider-round"></div>
              </h2>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-lg-8 offset-lg-2">
            <form @submit.prevent="">
              <div class="input-group input-group-lg input-group-round">
                <div class="input-group-inner">
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    placeholder="Search for answers"
                    required
                    v-model="searchKey"
                  />
                  <div class="input-group-append">
                    <button
                      type="button"
                      class="
                        btn btn-round btn-lg btn-icon-only btn-danger
                        mb-0
                      "
                    >
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                  <div class="input-focus-bg"></div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <!-- No data -->
        <div v-if="filteredData.length===0" class="row">
          <div class="col text-center">
            <p class="h3">No available data</p>
          </div>
        </div>

        <div class="row mb-5">
          <div class="col-lg-8 offset-lg-2">
            <div class="accordion" id="faqs">
              <div v-for="(item, index) in filteredData" :key="index.toString()" class="card">
                <div class="card-header shadow-sm" :id="'heading' + index">
                  <h5
                    class="accordion-title"
                    data-toggle="collapse"
                    :data-target="'#collapse' + index"
                    aria-expanded="true"
                    :aria-controls="'collapse' + index"
                  >
                  {{ item.question }}
                  </h5>
                </div>
                <div
                  :id="'collapse' + index"
                  class="collapse"
                  :aria-labelledby="'heading' + index"
                  data-parent="#faqs"
                >
                  <div v-html="item.answer" class="card-body">
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Call to action section -->
    <div
      class="section bg-image py-5"
      style="background-image: url('assets/images/faq-cta-bg.jpg')"
    >
      <div class="container">
        <div class="row my-5">
          <div class="col-lg-8 offset-lg-2">
            <div class="text-center my-4">
              <h4 class="font-weight-300 text-white mb-3 mb-md-2">
                We Are Here To Help You
              </h4>

              <h2 class="display-4 font-weight-800 text-white mb-4">
                Didn't Find an Answer?
              </h2>

              <div style="height: 10px"></div>

              <router-link
                to="/contact-us"
                class="btn btn-lg btn-round btn-outline-light mb-0"
                ><i class="fas fa-paper-plane"></i>Contact Us</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal login -->
    <div
      class="modal fade"
      id="modalLogin"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content rounded-xl">
          <div class="modal-body p-0">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true"></span>
            </button>

            <div class="row no-gutters">
              <div
                class="col-lg-6 d-none d-lg-block bg-image"
                style="
                  background-image: url('assets/images/directory-modal-login-img.jpg');
                "
              ></div>

              <div class="col-lg-6">
                <div class="px-4 px-md-5 px-lg-4 px-xl-5 py-5">
                  <div class="px-3 px-xl-5 py-4 py-xl-5">
                    <div class="mb-4 text-center">
                      <img
                        src="assets/svg/login-03.svg"
                        alt="Login"
                        style="width: 64px; height: 64px"
                      />
                    </div>

                    <div class="pb-1"></div>

                    <h3
                      class="
                        section-title-4
                        text-center
                        font-weight-800
                        pb-3
                        mb-4
                      "
                    >
                      User Login
                      <div class="title-divider-round"></div>
                    </h3>

                    <form>
                      <div
                        class="
                          input-group input-group-lg input-group-round
                          mb-4
                        "
                      >
                        <div class="input-group-inner">
                          <div class="input-group-prepend">
                            <span class="input-group-text input-group-icon"
                              ><i class="far fa-user"></i
                            ></span>
                          </div>
                          <input
                            type="text"
                            class="form-control form-control-lg"
                            placeholder="Username"
                          />
                          <div class="input-focus-bg"></div>
                        </div>
                      </div>

                      <div
                        class="
                          input-group input-group-lg input-group-round
                          mb-4
                        "
                      >
                        <div class="input-group-inner">
                          <div class="input-group-prepend">
                            <span class="input-group-text input-group-icon"
                              ><i class="far fa-eye-slash"></i
                            ></span>
                          </div>
                          <input
                            type="password"
                            class="form-control form-control-lg"
                            placeholder="Password"
                          />
                          <div class="input-focus-bg"></div>
                        </div>
                      </div>

                      <div style="height: 12px"></div>

                      <button
                        type="submit"
                        class="btn btn-lg btn-round btn-danger btn-block"
                      >
                        Sign in
                      </button>

                      <label class="w-100 text-center py-2 mb-0">
                        Forgot your password <a href="#">Click Here</a>
                      </label>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal register -->
    <div
      class="modal fade"
      id="modalRegister"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content rounded-xl">
          <div class="modal-body p-0">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true"></span>
            </button>

            <div class="row no-gutters">
              <div
                class="col-lg-6 d-none d-lg-block bg-image"
                style="
                  background-image: url('assets/images/directory-modal-register-img.jpg');
                "
              ></div>

              <div class="col-lg-6">
                <div class="px-4 px-md-5 px-lg-4 px-xl-5 py-5">
                  <div class="px-3 px-xl-5 py-4 py-xl-5">
                    <div class="mb-4 text-center">
                      <img
                        src="assets/svg/register-03.svg"
                        alt="Register"
                        style="width: 64px; height: 64px"
                      />
                    </div>

                    <div class="pb-1"></div>

                    <h3
                      class="
                        section-title-4
                        text-center
                        font-weight-800
                        pb-3
                        mb-4
                      "
                    >
                      Register
                      <div class="title-divider-round"></div>
                    </h3>

                    <form>
                      <div
                        class="
                          input-group input-group-lg input-group-round
                          mb-4
                        "
                      >
                        <div class="input-group-inner">
                          <div class="input-group-prepend">
                            <span class="input-group-text input-group-icon"
                              ><i class="far fa-user"></i
                            ></span>
                          </div>
                          <input
                            type="text"
                            class="form-control form-control-lg"
                            placeholder="Username"
                          />
                          <div class="input-focus-bg"></div>
                        </div>
                      </div>

                      <div
                        class="
                          input-group input-group-lg input-group-round
                          mb-4
                        "
                      >
                        <div class="input-group-inner">
                          <div class="input-group-prepend">
                            <span class="input-group-text input-group-icon"
                              ><i class="far fa-envelope"></i
                            ></span>
                          </div>
                          <input
                            type="email"
                            class="form-control form-control-lg"
                            placeholder="Email"
                          />
                          <div class="input-focus-bg"></div>
                        </div>
                      </div>

                      <div
                        class="
                          input-group input-group-lg input-group-round
                          mb-4
                        "
                      >
                        <div class="input-group-inner">
                          <div class="input-group-prepend">
                            <span class="input-group-text input-group-icon"
                              ><i class="far fa-eye-slash"></i
                            ></span>
                          </div>
                          <input
                            type="password"
                            class="form-control form-control-lg"
                            placeholder="Password"
                          />
                          <div class="input-focus-bg"></div>
                        </div>
                      </div>

                      <div style="height: 12px"></div>

                      <button
                        type="submit"
                        class="btn btn-lg btn-round btn-danger btn-block"
                      >
                        Register
                      </button>

                      <div
                        class="
                          form-group
                          custom-control custom-checkbox
                          text-center
                          py-2
                          mb-0
                        "
                      >
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="modalRegisterCheck"
                        />
                        <label
                          class="custom-control-label"
                          for="modalRegisterCheck"
                          >I agree to the
                          <a href="#" target="_blank">Terms and Conditions</a
                          >.</label
                        >
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      searchKey: '',
    }
  },
  computed: {
    ...mapState(['faqs']),
    filteredData() {
      if (!this.searchKey) {
        return this.faqs;
      } else {
        const results = [];
        this.faqs.forEach(d => {
          if (d.question.toLowerCase().includes(this.searchKey.toLowerCase()) || d.answer.toLowerCase().includes(this.searchKey.toLowerCase())) {
            results.push(d);
          }
        });

        return results;
      }
    }
  }
};
</script>

<style>
</style>