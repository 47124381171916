<template>
  <!-- Page title -->
  <div class="d-flex flex-column w-100">
    <div class="page-title d-flex align-items-center bg-image py-5"
      style="background-image: url('assets/images/page-title-bg-02.jpg')">
      <div class="container page-title-container">
        <div class="row">
          <div class="col text-center">

            <h1 class="display-3 font-weight-800 text-white mb-1">
              Corporate Governance
            </h1>

          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Breadcrumb -->
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="bg-danger py-4 mt-up50 rounded-xl shadow-lg">

          <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-light-2 px-md-4 mb-0">
              <li class="breadcrumb-item text-uppercase"><router-link to="/">Home</router-link></li>
              <li class="breadcrumb-item text-uppercase text-nowrap active" aria-current="page">Corporate Governance</li>
            </ol>
          </nav>

        </div>
      </div>
    </div>
  </div>

  <!-- Content -->
  <div class="main-content">

    <!-- Overview section -->
    <div class="section bg-white">
      <div class="container">

        <div class="row justify-content-center">
          <div class="col-md-7">
            <ul class="custom-list fa-ul mb-0">
              <li class="lead mb-2">
                <span class="fa-li"><i class="fas fa-link text-danger"></i></span>
                <a href="assets/pdf/PARK-Audit_Committee_Charter.pdf" target="_blank">
                  Audit Committee Charter</a>
              </li>
              <li class="lead mb-2">
                <span class="fa-li"><i class="fas fa-link text-danger"></i></span>
                <a href="assets/pdf/PARK-Compensation_Committee_Charter.pdf" target="_blank">
                  Compensation Committee Charter</a>
              </li>
              <li class="lead mb-4">
                <span class="fa-li"><i class="fas fa-link text-danger"></i></span>
                <a href="assets/pdf/PARK-Nominating_Committee_Charter.pdf" target="_blank">
                  Nominating and Corporate Governance Committee Charter</a>
              </li>
              <li class="lead mb-2">
                <span class="fa-li"><i class="fas fa-link text-danger"></i></span>
                <a href="assets/pdf/PARK-Code_of_Ethics.pdf" target="_blank">
                  Code of Ethics</a>
              </li>
              <li class="lead mb-2">
                <span class="fa-li"><i class="fas fa-link text-danger"></i></span>
                <a href="assets/pdf/PARK-Insider_Trading_Policy.pdf" target="_blank">
                  Insider Trading Policy</a>
              </li>
            </ul>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>