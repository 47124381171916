<template>
  <footer>
    <div class="bg-viridian-500 text-white-50">
      <div class="py-5">
        <div class="container">
          <div class="row py-5">
            <div class="col-lg-5 mb-5 mb-lg-0">
              <div class="widget">
                <img
                  class="img-fluid d-block mb-4 logo"
                  src="assets/images/logo-white.png"
                  alt="Mobile Infrastructure Logo"
                />
              </div>
            </div>

            <!-- <div class="col-lg-4 mb-5 mb-lg-0">
              <div class="widget">

                <h6 class="widget-title text-white text-uppercase font-weight-700">Recent posts</h6>

                <ul class="recent-blogs pt-2 mb-0">

                  <li v-for="post in posts.slice(0, 2)" :key="post.slug" class="pb-2 mb-4">
                    <div class="media">
                      <div class="hover-item hover-uncaption mr-4 rounded-circle shadow">
                        <router-link :to="{ name: 'Post', query: { slug: post.slug }}">
                          <div class="hover-transition rounded-circle">
                            <div class="hover-down">
                              <i class="fas fa-link fa-lg hover-icon"></i>
                            </div>
                          </div>
                          <figure>
                            <img :src="post.featured_image" :alt="post.featured_image_alt" style="width: 84px; height: 84px">
                          </figure>
                        </router-link>
                      </div>
                      <div class="media-body overflow-hidden">
                        <div class="pt-1 pb-2">
                          <router-link :to="{ name: 'Post', query: { slug: post.slug }}" class="text-white text-hover-primary font-weight-500 font-italic">{{ post.title }}</router-link>
                        </div>
                        <p class="mb-0 text-truncate">{{ post.summary }}</p>
                      </div>
                    </div>
                  </li>

                </ul>

              </div>
            </div> -->

            <div class="col-lg-4 offset-lg-1 mb-5 mb-lg-0">
              <div class="widget">
                <h6
                  class="widget-title text-white text-uppercase font-weight-700"
                >
                  Get in touch
                </h6>

                <div class="row mb-3">
                  <div class="col-2 col-lg-3 col-xl-2">
                    <span class="text-white font-weight-500">Address</span>
                  </div>

                  <div class="col-10 col-lg-9 col-xl-10">
                    <div class="pl-2">
                      30 West 4th Street<br />Cincinnati, OH 45202
                    </div>
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-2 col-lg-3 col-xl-2">
                    <span class="text-white font-weight-500">Phone</span>
                  </div>

                  <div class="col-10 col-lg-9 col-xl-10">
                    <div class="pl-2">+1 212 509 4000</div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-2 col-lg-3 col-xl-2">
                    <span class="text-white font-weight-500">Email</span>
                  </div>

                  <div class="col-10 col-lg-9 col-xl-10">
                    <div class="pl-2">BeepIR@Advisiry.com</div>
                  </div>
                </div>
              </div>

              <!-- <div class="widget">

                <h6 class="widget-title text-white text-uppercase font-weight-700 mb-4">Follow Us</h6>

                <div class="d-flex ml-n2">
                  <a href="#" target="_blank" class="d-flex align-items-center justify-content-center text-white-25 text-hover-primary btn-social">
                    <i class="fab fa-linkedin-in"></i>
                  </a>
                </div>

              </div> -->
            </div>

            <div class="col-lg-2">
              <div class="widget">
                <h6
                  class="
                    widget-title
                    text-white text-uppercase
                    font-weight-700
                    mb-4
                  "
                >
                  Follow Us
                </h6>

                <div class="d-flex ml-n2">
                  <a
                    v-for="s in socials"
                    :key="s.linkType"
                    :href="s.link"
                    target="_blank"
                    class="
                      d-flex
                      align-items-center
                      justify-content-center
                      text-white-25 text-hover-primary
                      btn-social
                    "
                  >
                    <i :class="getIcon(s.linkType)"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-bottom py-4">
        <div class="container footer-bottom-container">
          <div class="row py-3">
            <div class="col-lg-6 text-center text-lg-left mb-2 mb-lg-0">
              <small
                >Copyright @ <router-link to="/">mobileit.com</router-link>. All
                Rights Reserved.</small
              >
            </div>

            <div class="col-lg-6 text-center text-lg-right">
              <router-link
                to="/faq"
                class="text-white-50 text-hover-primary mr-4"
                >FAQ</router-link
              >
              <router-link
                to="/terms-of-use"
                class="text-white-50 text-hover-primary mr-4"
                >Terms of Use</router-link
              >
              <router-link
                to="/privacy-policy"
                class="text-white-50 text-hover-primary mr-4"
                >Privacy Policy</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "m-footer",
  computed: {
    ...mapState([
      "socials",
      // 'posts'
    ]),
  },
  methods: {
    getIcon(type) {
      switch (type) {
        case "LinkedIn":
          return "fab fa-linkedin-in";
        case "Facebook":
          return "fab fa-facebook-square";
        case "Google":
          return "fab fa-google";
        case "Twitter":
          return "fab fa-twitter-square";

        default:
          return "fab fa-question-circle";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
