<template>
  <!-- Page title -->
  <div class="d-flex flex-column w-100">
    <div class="page-title d-flex align-items-center bg-image py-5"
      style="background-image: url('assets/images/board-bg.jpg')">
      <div class="container page-title-container">
        <div class="row">
          <div class="col text-center">

            <h1 class="display-3 font-weight-800 text-white mb-4 mb-md-0">
              Board
            </h1>

          </div>
        </div>
      </div>
    </div>
  </div>
  
  <!-- Breadcrumb -->
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="bg-danger py-4 mt-up50 rounded-xl shadow-lg">

          <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-light-2 px-md-4 mb-0">
              <li class="breadcrumb-item text-uppercase"><router-link to="/">Home</router-link></li>
              <li class="breadcrumb-item text-uppercase active" aria-current="page">Board</li>
            </ol>
          </nav>

        </div>
      </div>
    </div>
  </div>

  <!-- Content -->
  <div class="main-content">

    <div class="section">
      <div class="container">
        
        <!-- No data -->
        <div v-if="filteredData.length===0" class="row">
          <div class="col text-center">
            <p class="h3">No available data</p>
          </div>
        </div>

        <!-- Tag section -->
        <div v-if="filteredData.length>0" class="row align-items-center pb-4 pb-xl-5">

          <div class="col-12 text-center text-lg-right mb-3 mb-lg-4"><b>Questions?</b><a href="mailto:Secretary@MobileIT.com" class="ml-3">Secretary@MobileIT.com</a></div>

          <div class="col-xl-12">
            <div class="grid-item-filter">
              <ul class="nav nav-pills justify-content-center justify-content-xl-end">
                <li class="nav-item my-2">
                  <a :class="{'active': currentTag==='all'}" class="nav-link" href="#" @click.prevent="currentTag='all'">All</a>
                </li>
                <li v-for="t in tags" :key="t.id" class="nav-item my-2">
                  <a :class="{'active': currentTag===t}" class="nav-link" href="#" @click.prevent="currentTag=t">{{ t.name }}</a>
                </li>
              </ul>
            </div>
          </div>

        </div>

        <!-- Content section -->
        <div v-if="filteredData.length>0" class="row">

          <!-- Grid item -->
          <div v-for="item in filteredData" :key="item.id" class="col-md-6 col-lg-4 grid-item cat-all cat-mockup cat-media">
            <div class="card card-blog-entry border-0 plain-card mb-2">
              <div class="card-blog-img hover-item hover-scale hover-uncaption rounded">
                <a :href="item.socialLinks[0].link" target="_blank">
                  <div class="hover-transition">
                    <div class="hover-down">
                      <i class="fab fa-linkedin-in fa-2x hover-icon"></i>
                    </div>
                  </div>
                  <figure class="text-center">
                    <img class="img-fluid" :src="item.image" :alt="item.name">
                  </figure>
                </a>
              </div>
              <div class="card-body">
                <h5 class="font-weight-700 mt-2">
                  <a :href="item.linkedin" target="_blank" class="text-dark-gray">{{ item.name }}</a>
                </h5>
                <h6 class="text-primary mb-3">{{ item.title }}</h6>
                <ul class="meta-entry mb-4">
                  <li v-for="t in item.tags" :key="t.id" class="meta-entry-item">
                    <a href="#" @click.prevent="currentTag=t">{{ t.name }}</a>
                  </li>
                </ul>
                <p v-html="truncate(item.bio, item.truncate)" class="card-text"></p>
              </div>
              <div class="card-footer border-0 bg-transparent">
                <div class="meta-item float-left mb-2">
                  <a href="" @click.prevent="readMore(item)"><i class="far fa-list-alt text-black-50"></i>Read more</a>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>

  </div>

  <!-- Modal -->
  <div class="modal fade" id="modalBio" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content rounded-xl bg-image">
        <div class="modal-body p-0">

          <button type="button" class="close close-white" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"></span>
          </button>

          <div class="px-4 py-5">
            <div class="row">
              <div class="col-12">
                <div class="text-center">

                  <img class="img-fluid mb-3" :src="item?.image" :alt="item?.name" width="200" height="200">

                  <h5 class="font-weight-700 mt-2">{{ item?.name }}</h5>
                  <h6 class="text-primary mb-3">{{ item?.title }}</h6>
                  <ul class="meta-entry mb-4">
                    <li v-for="t in item?.tags" :key="t.id" class="meta-entry-item">
                      <a>{{ t.name }}</a>
                    </li>
                  </ul>

                </div>

                <div v-html="item?.bio"></div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

const $ = window.$;

export default {
  data() {
    return {
      tags: [],
      currentTag: 'all',
      item: null,
    }
  },
  computed: {
    ...mapState(['boards']),
    filteredData() {
      if (this.currentTag === 'all') {
        return this.boards;
      } else {
        const results = [];
        this.boards.forEach(e => {
          const index = e.tags.findIndex(x => x.id === this.currentTag.id);
          if (index > -1) {
            results.push(e);
          }
        });

        return results;
      }
    }
  },
  methods: {
    truncate(text) {
      if (text.length < 500) {
        return text;
      }
      
      return text.slice(0, 500) + '...';
    },
    readMore(item) {
      console.log('abc: ', window)
      this.item = item;
      $('#modalBio').modal('show');
    },
    setTags() {
      if (this.boards.length === 0) {
        return;
      }

      this.boards.forEach(e => {
        e.tags.forEach(t => {
          const index = this.tags.findIndex(x => x.id === t.id);
          if (index === -1) {
            this.tags.push(t);
          }
        });
      });

      this.tags.sort((a, b) => { 
        return a.order - b.order || a.name.localeCompare(b.name);
      });
    }
  },
  created() {
    this.setTags();
  },
  watch: {
    boards() {
      this.setTags();
    }
  }
}
</script>

<style lang="scss" scoped>
  .card {
    .img-fluid {
      height: 400px;
      width: 100%;
      object-fit: cover;

      @media (max-width: 768px) {
        height: 300px;
      }
    }
  }
</style>