import { createWebHistory, createRouter } from "vue-router";

// import Home from './pages/Home.vue'
import WhyParking from "./pages/WhyParking.vue";
// import Mobility from './pages/mobility/Mobility.vue'
// import Parking from './pages/mobility/Parking.vue'
// import EV from './pages/mobility/EV.vue'
// import Scooters from './pages/mobility/Scooters.vue'
// import InvestorRelations from "./pages/investor-relations/InvestorRelations.vue";
import InvestorHighlights from "./pages/investor-relations/InvestorHighlights.vue";
import CorporateGovernance from "./pages/investor-relations/CorporateGovernance.vue";
import InvestorPaperwork from "./pages/investor-relations/InvestorPaperwork.vue";
import News from "./pages/investor-relations/News.vue";
import SECFilings from "./pages/investor-relations/SECFilings.vue";
import ParkingLocations from "./pages/parking-locations/ParkingLocations.vue";
import Portfolio from "./pages/parking-locations/Portfolio.vue";
import AboutUs from "./pages/about-us/AboutUs.vue";
import Team from "./pages/about-us/Team.vue";
import Board from "./pages/about-us/Board.vue";
import Blog from "./pages/blog/Blog.vue";
import Post from "./pages/blog/Post.vue";
import ContactUs from "./pages/ContactUs.vue";
import FAQ from "./pages/FAQ.vue";
import PrivacyPolicy from "./pages/PrivacyPolicy.vue";
import TermsOfUse from "./pages/TermsOfUse.vue";
import Deloitte from "./pages/landings/Deloitte.vue";
import SpecialAnnouncments from "./pages/SpecialAnnouncements.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: WhyParking,
    meta: {
      title: "Home - Mobile Infrastructure",
    },
  },
  // {
  //   path: '/why-parking',
  //   name: 'WhyParking',
  //   component: WhyParking,
  //   meta: {
  //     title: 'Why Parking - Mobile Infrastructure'
  //   }
  // },
  // {
  //   path: '/mobility',
  //   name: 'Mobility',
  //   component: Mobility
  // },
  // {
  //   path: '/parking',
  //   name: 'Parking',
  //   component: Parking
  // },
  // {
  //   path: '/ev',
  //   name: 'EV',
  //   component: EV
  // },
  // {
  //   path: '/scooters',
  //   name: 'Scooters',
  //   component: Scooters
  // },
  {
    path: "/parking-locations",
    name: "ParkingLocations",
    component: ParkingLocations,
    meta: {
      title: "Parking Locations - Mobile Infrastructure",
    },
  },
  {
    path: "/portfolio",
    name: "Portfolio",
    component: Portfolio,
    meta: {
      title: "Portfolio - Mobile Infrastructure",
    },
  },
  {
    path: "/about-us",
    name: "AboutUs",
    component: AboutUs,
    meta: {
      title: "About Us - Mobile Infrastructure",
    },
  },
  {
    path: "/team",
    name: "Team",
    component: Team,
    meta: {
      title: "Team - Mobile Infrastructure",
    },
  },
  {
    path: "/board",
    name: "Board",
    component: Board,
    meta: {
      title: "Board - Mobile Infrastructure",
    },
  },
  {
    path: "/blog",
    name: "Blog",
    component: Blog,
    meta: {
      title: "Blog - Mobile Infrastructure",
    },
  },
  {
    path: "/post",
    name: "Post",
    component: Post,
  },
  {
    path: "/investor-relations",
    name: "InvestorRelations",
    beforeEnter() {
      window.location.replace("https://ir.mobileit.com")
    }
  },
  {
    path: "/investor-highlights",
    name: "InvestorHighlights",
    component: InvestorHighlights,
    meta: {
      title: "Investor Highlights - Mobile Infrastructure",
    },
  },
  {
    path: "/corporate-governance",
    name: "CorporateGovernance",
    component: CorporateGovernance,
    meta: {
      title: "Corporate Governance - Mobile Infrastructure",
    },
  },
  {
    path: "/investor-paperwork",
    name: "InvestorPaperwork",
    component: InvestorPaperwork,
    meta: {
      title: "Investor Paperwork - Mobile Infrastructure",
    },
  },
  {
    path: "/news",
    name: "News",
    component: News,
    meta: {
      title: "News - Mobile Infrastructure",
    },
  },
  {
    path: "/sec-filings",
    name: "SEC Filings",
    component: SECFilings,
    meta: {
      title: "SEC Filings - Mobile Infrastructure",
    },
  },
  {
    path: "/contact-us",
    name: "ContactUs",
    component: ContactUs,
    meta: {
      title: "Contact Us - Mobile Infrastructure",
    },
  },
  {
    path: "/faq",
    name: "FAQ",
    component: FAQ,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/terms-of-use",
    name: "TermsOfUse",
    component: TermsOfUse,
  },
  {
    path: "/landing/cincinnati/deloitte",
    name: "Deloitte",
    component: Deloitte,
  },
  {
    path: "/landing/deloitte",
    name: "Deloitte",
    component: Deloitte,
  },
  {
    path: "/specialannouncements",
    name: "Special Announcements",
    component: SpecialAnnouncments,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
