<template>
  <!-- Page title -->
  <div class="w-100">
    <div class="d-flex flex-column w-100">
      <div
        class="page-title d-flex align-items-center bg-image py-5"
        style="background-image: url('assets/images/sec-filings.jpg')"
      >
        <div class="container page-title-container">
          <div class="row py-5">
            <div class="col text-center">

              <h1 class="display-4 font-weight-800 text-white mb-4">
                SEC Filings (Current)
              </h1>              

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <!-- Content -->
  <div class="main-content py-0">
    <!-- Accordion answers section -->
    <div class="section py-5">
      <!-- Preloader -->
      <div v-if="loading" class="row mt-5 mb-5">
        <div class="col">
          <div class="preloader-wrapper">
            <div class="preloader-border"></div>
            <div class="preloader-line-mask">
              <div class="preloader-line"></div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!loading" class="container">
        <div class="row my-5">
          <div class="col-lg-10 offset-lg-1">
            <table class="table">
              <thead>
                <tr>
                  <th @click="changeSort('filingDate')">Filing Date <i class="fas" :class="sort.key !== 'filingDate' ? 'fa-sort' : sort.dir === 'up' ? 'fa-sort-up' : 'fa-sort-down'"></i></th>
                  <th @click="changeSort('formType')">Form Type <i class="fas" :class="sort.key !== 'formType' ? 'fa-sort' : sort.dir === 'up' ? 'fa-sort-up' : 'fa-sort-down'"></i></th>
                  <th @click="changeSort('formDescription')">Form Description <i class="fas" :class="sort.key !== 'formDescription' ? 'fa-sort' : sort.dir === 'up' ? 'fa-sort-up' : 'fa-sort-down'"></i></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in filteredData" :key="index.toString()">
                  <td>{{ item.filingDate }}</td>
                  <td>{{ item.formType }}</td>
                  <td>{{ item.formDescription }}</td>
                  <td>
                    <a :href="item.link" target="_blank">Link</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-12 text-center">
            <a href="https://www.sec.gov/edgar/browse/?CIK=1642985" target="_blank" class="btn btn-outline-danger">View Archives</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      loading: true,
      sort: {
        key: '',
        dir: ''
      },
      data: []
    }
  },
  computed: {
    filteredData() {
      const { key, dir } = this.sort;
      if (!key || !dir) {
        return this.data;
      }
      
      if (dir === 'up') {
        return [...this.data].sort((a, b) => (a[key.toLowerCase()] > b[key].toLowerCase()) ? 1 : ((b[key].toLowerCase() > a[key].toLowerCase()) ? -1 : 0) )
      } else {
        return [...this.data].sort((a, b) => (b[key.toLowerCase()] > a[key].toLowerCase()) ? 1 : ((a[key].toLowerCase() > b[key].toLowerCase()) ? -1 : 0) )
      }
      
    }
  },
  created() {
    this.loading = true;

    axios.get('https://data.sec.gov/rss?cik=1642985&count=100').then(res => {
      const feed = new window.DOMParser().parseFromString(res.data, "text/xml");
      const entries = feed.querySelectorAll("entry");
      this.data = [...entries].map(el => ({
        filingDate: el.querySelector('content-type').querySelector('filing-date').innerHTML,
        formType: el.querySelector('category').getAttribute('term'),
        formDescription: el.querySelector('content-type').querySelector('form-name').innerHTML,
        link: el.querySelector('content-type').querySelector('filing-href').innerHTML
      }));

      this.loading = false;
    }).catch(() => {
      this.data = [];

      this.loading = false;
    })
  },
  methods: {
    changeSort(key) {
      if (this.sort.key === key) {
        const dir = this.sort.dir === 'up' ? 'down' : 'up';
        this.sort = {
          key,
          dir
        }
      } else {
        this.sort = {
          key,
          dir: 'up'
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  th {
    cursor: pointer;
  }
</style>