<template>
  <!-- Page title -->

  <div class="d-flex flex-column w-100">
    <div class="page-title d-flex align-items-center bg-image py-5"
      style="background-image: url('assets/images/page-title-bg-02.jpg')">
      <div class="container page-title-container">
        <div class="row">
          <div class="col text-center">

            <h1 class="display-3 font-weight-800 text-white mb-4 mb-md-0">
              Investor Paperwork
            </h1>

          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Breadcrumb -->
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="bg-danger py-4 mt-up50 rounded-xl shadow-lg">

          <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-light-2 px-md-4 mb-0">
              <li class="breadcrumb-item text-uppercase"><router-link to="/">Home</router-link></li>
              <li class="breadcrumb-item text-uppercase text-nowrap active" aria-current="page">Investor Paperwork</li>
            </ol>
          </nav>

        </div>
      </div>
    </div>
  </div>

  <!-- Content -->
  <div class="main-content pb-0">

    <!-- Overview section -->
    <div class="section bg-white pb-5">
      <div class="container">

        <div class="row mb-3">
          <div class="col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
            <div class="text-center">

              <h2 class="h1 section-title-2 text-center font-weight-800">
                Communications
              </h2>

            </div>
          </div>
        </div>

        <div class="row justify-content-center mb-5">
          <div class="col-md-6">
            <ul class="custom-list fa-ul mb-0">
              <li class="mb-2">
                <span class="fa-li"><i class="fas fa-link text-danger"></i></span>
                <a href="assets/pdf/TPR-PreferredShareholderStopDistributions03302020.pdf" target="_blank">
                  March 30, 2020 Letter to Preferred Shareholders</a>
              </li>
            </ul>
          </div>
        </div>

      </div>
    </div>

    <!-- Services section -->
    <div class="section bg-light py-5">
      <div class="container">

        <div class="row my-5">
          <div class="col-lg-8 offset-lg-2">
            <div class="text-center">

              <h2 class="h1 section-title-4 font-weight-800 mb-4">
                The Mobile Infrastructure Forms
                <div class="title-divider-round"></div>
              </h2>

            </div>
          </div>
        </div>

        <div class="row justify-content-center my-5">

          <div class="col-md-6">
            <ul class="custom-list fa-ul mb-0">
              <li class="mb-2">
                <span class="fa-li"><i class="fas fa-link text-danger"></i></span>
                <a href="assets/pdf/MI-DistributionChangeForm.pdf" target="_blank">
                  Distribution Change Form</a>
              </li>
              <li class="mb-2">
                <span class="fa-li"><i class="fas fa-link text-danger"></i></span>
                <a href="assets/pdf/MI-TransferOnDeath.pdf" target="_blank">
                  Transfer on Death (T.O.D.)</a>
              </li>
              <li class="mb-2">
                <span class="fa-li"><i class="fas fa-link text-danger"></i></span>
                <a href="assets/pdf/MI-ShareTransfer.pdf" target="_blank">
                  Share Transfer</a>
              </li>
              <li class="mb-2">
                <span class="fa-li"><i class="fas fa-link text-danger"></i></span>
                <a href="assets/pdf/MI-AddressChangeForm.pdf" target="_blank">
                  Investor Address Change</a>
              </li>
              <li class="mb-2">
                <span class="fa-li"><i class="fas fa-link text-danger"></i></span>
                <a href="assets/pdf/MI-BrokerDealerChangeForm.pdf" target="_blank">
                  Broker Dealer Change Form</a>
              </li>
              <li class="mb-2">
                <span class="fa-li"><i class="fas fa-link text-danger"></i></span>
                <a href="assets/pdf/TheParkingREIT-AffidvitOfSuccessorTrustee.pdf" target="_blank">
                  Affidavit of Succ. TTEE (to be used for a Trustee’s death)</a>
              </li>
            </ul>
          </div>

        </div>

      </div>
    </div>

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>